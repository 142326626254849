import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html'
})
export class DialogComponent implements OnInit {

    dialogPattern: ('confirm' | 'warning' | 'error' | 'info');

    iconName: string;
    iconColor: string;
    defaltIconColor = 'rgba(20, 20, 20, 0.7)';
    title: string;
    content: string;
    buttonPosition: ('left' | 'center' | 'right');
    positionStyle: string;
    defaltButtonPosition: ('left' | 'center' | 'right') = 'center';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogComponent>
    ) { }

    ngOnInit(): void {
        const config = this.data;
        this.iconName = config.iconName;
        if (!config.iconColor) {
            this.iconColor = this.defaltIconColor;
        }
        this.title = config.title;
        this.content = config.content;
        switch (config.buttonPosition) {
            case 'left':
                this.positionStyle = '';
                break;
            case 'center':
                this.positionStyle = 'center';
                break;
            case 'right':
                this.positionStyle = 'flex-end';
                break;
            default:
                this.positionStyle = '';
        }

        if (!config.dialogPattern) {
            throw new Error('dialogPattern is not declaration');
        }
        this.dialogPattern = config.dialogPattern;
    }
}