import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";


export interface SubclassSelectionModel {
    selectedSubclass: SubclassSelectionStatus[];
    taggingPreset: string[];
}

export interface SubclassSelectionStatus {
    name: string,
    isSelected: boolean
}


@Component({
    selector: 'app-subclass-selection',
    templateUrl: './subclass-selection.component.html',
    styleUrls: ['./subclass-selection.component.css']
})

export class SubclassSelectionComponent implements OnInit {

    selectedSubclasses: SubclassSelectionStatus[];
    taggingPreset: string[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SubclassSelectionModel,
        public dialogRef: MatDialogRef<SubclassSelectionComponent>
    ) {

    }

    ngOnInit() {
        this.selectedSubclasses = this.data.selectedSubclass
        this.taggingPreset = this.data.taggingPreset

    }


    clear() {
        this.selectedSubclasses
            .filter(subclass => subclass.isSelected)
            .forEach(subclass => {
                subclass.isSelected = false
            })
    }

    selectTaggingPreset() {
        this.selectedSubclasses
            .filter((subclass) => {
                return !subclass.isSelected && this.taggingPreset.indexOf(subclass.name) > -1
            }).forEach((subclass) => {
                subclass.isSelected = true;
            });
    }

    ok() {
        let ret = this.selectedSubclasses
            .filter(subclass => subclass.isSelected)
            .map(subclass => subclass.name)

        this.dialogRef.close(ret)
    }

    cancel() {
        this.dialogRef.close()
    }

}

