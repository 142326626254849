import { Component, OnInit, ElementRef, Input, Output, ViewChild, EventEmitter, OnDestroy, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { throttle } from 'rxjs/operators';
import { CommonUtils, CursorPosition } from '../../utils/common';

export interface ColorEvent {
  code: string
  isClick: boolean
}

@Component({
  selector: 'app-color',
  templateUrl: 'color.component.html',
  styleUrls: ['./color.component.css']
})
export class ColorComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasWrapper') canvasWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('stalker') stalker: ElementRef<HTMLDivElement>;

  @Input('imgPath') imgPath: string;
  @Input('rangeList') rangeList: number[];
  @Output() colorEvent = new EventEmitter<ColorEvent>();
  @Output() cursorPositionEvent = new EventEmitter<CursorPosition>();

  private image: HTMLImageElement;
  private ctx: any;

  xRange: string
  yRange: string

  private xRangeCenter: number;
  private yRangeCenter: number;

  private cursorNotificator: ($event: MouseEvent) => void;
  unsubscriptions: Subscription[] = []


  ngOnInit() {
    var stalker = this.stalker.nativeElement
    stalker.style.border = 'solid 1px';
    stalker.style.borderColor = '#58FA58';
    stalker.style.pointerEvents = 'none';
    this.stalker.nativeElement.style.position = 'absolute'

    this.xRange = `${this.rangeList[0]}px`;
    this.yRange = `${this.rangeList[1]}px`;

    this.xRangeCenter = this.calcCenter(this.rangeList[0])
    this.yRangeCenter = this.calcCenter(this.rangeList[1])

    this.initialize(this.imgPath)

  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnDestroy()
    this.ngOnInit()
  }

  ngOnDestroy(): void {
    this.unsubscriptions.forEach(x => {
      x.unsubscribe()
    })
  }

  initialize(src: string) {
    this.image = new Image()
    this.image.crossOrigin = 'Anonymous'

    this.image.onload = (e) => {
      let width = this.image.width;
      let height = this.image.height;

      this.canvasWrapper.nativeElement.style.width = `${width}px`
      this.canvasWrapper.nativeElement.style.height = `${height}px`

      var canvas = this.canvas.nativeElement
      canvas.style.cursor = 'crosshair'
      canvas.style.position = 'absolute';
      canvas.width = width
      canvas.height = height

      this.ctx = canvas.getContext('2d');
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);

      var x = (canvas.width - width) / 2;
      var y = (canvas.height - height) / 2;
      this.ctx.drawImage(this.image, x, y);
      this.setEventBinding()
    }

    this.image.src = src
  }

  setEventBinding() {
    this.cursorNotificator = CommonUtils.getCursorNotificator(this.cursorPositionEvent)
    this.unsubscriptions = [
      fromEvent<MouseEvent>(this.canvas.nativeElement, 'mousemove')
        .pipe(
          // throttle(val => interval(100))
        ).subscribe(
          (evt) => {
            this.notifyColorEvent(evt, false)
          }
        ),
      fromEvent<MouseEvent>(this.canvas.nativeElement, 'click')
        .subscribe(
          (evt) => {
            this.notifyColorEvent(evt, true)
          }
        )]
  }

  private notifyColorEvent(evt, isClick) {
    
    this.cursorNotificator(evt)

    // mouse move event config
    let x = parseInt(evt.offsetX);
    let y = parseInt(evt.offsetY);

    let xCenter = x - this.xRangeCenter
    let yCenter = y - this.yRangeCenter

    this.stalker.nativeElement.style.left = this.clamp(xCenter, 0, this.image.width - this.xRangeCenter) + 'px'
    this.stalker.nativeElement.style.top = this.clamp(yCenter, 0, this.image.height - this.yRangeCenter) + 'px'

    // Color 取得
    var imagedata = this.ctx.getImageData(x, y, this.rangeList[0], this.rangeList[1]);

    var r = 0
    var g = 0
    var b = 0
    var a = 0

    var index = 0
    while (index < imagedata.data.length) {
      r += imagedata.data[index + 0];
      g += imagedata.data[index + 1];
      b += imagedata.data[index + 2];
      a += imagedata.data[index + 3];
      index = index + 4;
    }

    var pix = imagedata.data.length / 4

    r = Math.round(r / pix)
    g = Math.round(g / pix)
    b = Math.round(b / pix)

    var colorCode = this.toColorCode(r, g, b)

    this.colorEvent.emit({
      code: colorCode,
      isClick: isClick
    })
  }

  calcCenter(num: number) {
    let ret = num / 2
    return Number.isInteger(ret) ? ret : Math.round(ret)
  }

  clamp(num, min, max) {
    return num <= min ? min : num >= max ? max : num;
  }

  private toColorCode(r, g, b) {
    r = r.toString(16);
    if (r.length == 1) r = "0" + r;

    g = g.toString(16);
    if (g.length == 1) g = "0" + g;

    b = b.toString(16);
    if (b.length == 1) b = "0" + b;

    return '#' + r + g + b
  }
}
