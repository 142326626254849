import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ICellEditorParams, ICellRendererParams } from 'ag-grid';
import { AgEditorComponent, AgRendererComponent } from 'ag-grid-angular';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NamedValue } from 'src/app/model/common'

export interface AgGridBtnComponentParam extends ICellRendererParams {
  AgGridBtnComponent: {
    isDisabled: (params: AgGridBtnComponentParam) => boolean;
    isHidden: (params: AgGridBtnComponentParam) => boolean;
    onClick?: (isClick: boolean, params: AgGridBtnComponentParam) => string;
    doReset?: (params: AgGridBtnComponentParam) => boolean;
    [key: string]: any;
  }
}

export class AgGridBtnComponent implements AgRendererComponent {
  

  params: AgGridBtnComponentParam;

  agInit(params: AgGridBtnComponentParam): void {

    this.params = params
  }

  onClick(isClick) {
    this.params.AgGridBtnComponent.onClick(isClick, this.params)
  }

  refresh(params: AgGridBtnComponentParam): boolean {
    return true;
  }

}

@Component({
  selector: 'ag-grid-btn-component',
  template: `
    <span *ngIf="params" [hidden]="params.AgGridBtnComponent.isHidden(params)">
        <button [disabled]="params.AgGridBtnComponent.isDisabled(params)" class="material-icons btn btn-sm btn-primary" (click)="onClick()"><font size="2">{{isClick ?'cancel' : 'crop_free' }}</font></button>
    </span>
    `
})
export class AgGridAttachComponent extends AgGridBtnComponent {
  isClick = false

  onClick() {
    this.isClick = !this.isClick
    super.onClick(this.isClick)
  }
}

@Component({
  selector: 'ag-grid-color-selector',
  template: `
    <span  *ngIf="params">
        <span [style.background-color]="params.value" style="display: inline-block; width: 30px; height: 30px; border:solid 1px; margin-right: 5px;"
              [ngStyle]="params.value || isClick ? {} : {'background-image': 'linear-gradient(-45deg, transparent 49%, black 49%, black 51%, transparent 51%, transparent)'}"></span>
        <div class="btn-group btn-group-sm" role="group" style="position:relative; bottom:10px;">
          <button [disabled]="params.AgGridBtnComponent.isDisabled(params)" class="material-icons btn btn-primary" (click)="onClick()">{{isClick ? 'cancel' : 'colorize'}}</button>
          <button [disabled]="params.AgGridBtnComponent.isDisabled(params)" class="material-icons btn btn-danger" (click)="doReset(params)">delete_forever</button>
        </div>
     </span>
    `
})
export class AgGridColorSelectorComponent extends AgGridBtnComponent {

  isClick = false

  refresh(params): boolean {
    this.params.value = params.value
    return true;
  }

  onClick() {
    this.isClick = !this.isClick
    super.onClick(this.isClick)
  }

  doReset(params) {
    this.params.AgGridBtnComponent.doReset(this.params)
  }
}

@Component({
  selector: 'ag-grid-remove-btn',
  template: `
    <span  *ngIf="params">
        <button class="btn btn-danger btn-xs" (click)="onClick()" [disabled]="params.AgGridBtnComponent.isDisabled(params)">
          <span class="glyphicon glyphicon-remove"></span>
        </button>
    </span>
    `
})
export class AgGridRemoveComponent extends AgGridBtnComponent {
}

@Component({
  selector: 'ag-grid-flag-btn',
  template: `
  <span  *ngIf="params">
  <button [ngClass]="isClick ? 'btn btn-success' : 'btn btn-default'" (click)="onClick()" [disabled]="params.AgGridBtnComponent.isDisabled(params)">
  {{isClick}}
  </button>
</span>
    `
})
export class AgGridFlagBtn extends AgGridBtnComponent {

  isClick = false

  agInit(params: AgGridBtnComponentParam): void {
    this.params = params
    this.isClick = !!this.params.value
  }

  refresh(params: AgGridBtnComponentParam): boolean {
    this.params.value = params.value
    return true;
  }

  onClick() {
    this.isClick = !this.isClick
    let row = this.params.api.getDisplayedRowAtIndex(this.params.rowIndex)
    row.setDataValue(this.params.column, this.isClick)
  }

}

export interface AgGridSelectEditorParam extends ICellEditorParams {
  AgGridSelectEditorParam: {
    values: NamedValue[]
  }
}

@Component({
  selector: 'ag-grid-autocomplete',
  template: `
  <div *ngIf="param">
    <ng-select #ngSelect
      [ngStyle]="{'width': width}" style="font-size: 10px; padding-top: 0; padding-bottom: 0;"
      [items]="param.AgGridSelectEditorParam.values"
      bindLabel="name"
      bindValue="value"
      appendTo="body"
      (focus)="onFocus()"
      (close)="onClose()"
      (change)="onChange($event)"
      [(ngModel)]="selected">
    </ng-select>
  </div>
  `
})
export class AgGridSelectEditor implements AgEditorComponent, AfterViewInit, OnDestroy {

  private unsubscribe$ = new Subject();
  selected: string;
  width: string;
  param: AgGridSelectEditorParam;

  suppresNavigate: Subscription;
  unsubscriber: Subscription[] = []

  @ViewChild(NgSelectComponent) selector: NgSelectComponent;

  agInit(param: AgGridSelectEditorParam) {
    this.param = param
    this.width = this.param.column.getActualWidth() + "px"
    this.selected = this.param.value
  }

  ngAfterViewInit() {

    this.selector.open()
    setTimeout(() => {
      this.selector.focus()
    })
  }

  private _suppreseNavigate() {
    this.suppresNavigate = fromEvent<KeyboardEvent>(this.selector.elementRef.nativeElement, 'keydown')
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(x => x.keyCode == 37
          || x.keyCode == 38
          || x.keyCode == 39
          || x.keyCode == 40)
      ).subscribe(x => {
        x.stopImmediatePropagation()
      })
  }

  onChange(event) {
    this.selector.close()
  }

  onFocus() {
    this._suppreseNavigate()
  }

  onClose() {
    this.param.api.stopEditing();
    setTimeout(() => {
      this.param.api.setFocusedCell(this.param.rowIndex, this.param.column)
    });
  }


  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
    this.unsubscriber.forEach(x => x.unsubscribe())
  }

  getValue() {
    return this.selected ? this.selected : undefined
  }

  refresh(param: any) {
    return false
  }

  isPopup() {
    return false
  }

}
