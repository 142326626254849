import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WebTaggingComponent } from 'src/app/page/web-tagging/web-tagging.component';
import { WebTaggingForTemplateGroupComponent } from 'src/app/page/web-tagging-for-template-group/web-tagging-for-template-group.component';
import { TaggingLoginComponent } from 'src/app/page/login/login.component';
import { TaggingHomeComponent } from 'src/app/page/tagging/tagging.component';
import { TaggingStatusListComponent } from 'src/app/page/status-list/status-list.component';
import { TaggingStatusDetailComponent, TemplateStatusResolver } from 'src/app/page/status-detail/status-detail.component';
import { ProjectRegistComponent } from 'src/app/page/project-regist/project-regist.component';
import { AuthorizationGuard } from 'src/app/service/user/uac';
import { ProjectListComponent } from './page/project-list/project-list.component';
import { ProjectDetailComponent } from './page/project-detail/project-detail.component';
import { TaggingLockerComponent } from './page/lock/lock.component';
import { DatasetViewerComponent } from './page/dataset-viewer/dataset-viewer.component';
import { ProjectExportComponent } from './page/project-export/project-export.component';
import { HomeComponent } from './page/home/home.component';
import { InstagramPostCheckerComponent } from './page/tool/instagram-post-checker/instagram-post-checker.component';
import { DatasetDeleteManagementComponent } from './page/dataset-delete-management/dataset-delete-management.component';
import { ROLEGROUPE } from './config/constants';
import { ErrorComponent } from './page/error/error.component';

export const routes: Routes = [
  { path: 'error', component: ErrorComponent},
  { path: '', pathMatch: 'full', redirectTo: 'tag/login' },
  { path: 'home', pathMatch: 'full', component: HomeComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager, ...ROLEGROUPE.annotator]}},
  { path: 'tag/login', component: TaggingLoginComponent },
  { path: 'tag/home', component: TaggingHomeComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager, ...ROLEGROUPE.annotator]} },
  { path: 'tag/status-list', component: TaggingStatusListComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager, ...ROLEGROUPE.annotator]} },
  {
    path: 'tag/status-detail/:templateId', component: TaggingStatusDetailComponent, canActivate: [AuthorizationGuard],
    resolve: {
      status: TemplateStatusResolver
    }, data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager, ...ROLEGROUPE.annotator]}
  },
  { path: 'tag/web-tagging/:templateId', component: WebTaggingComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager, ...ROLEGROUPE.annotator]} },
  { path: 'tag/web-tagging/template-group/:templateGroupId', component: WebTaggingForTemplateGroupComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager, ...ROLEGROUPE.annotator]} },
  { path: 'tag/project/new', component: ProjectRegistComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin]} },
  { path: 'tag/project-list', component: ProjectListComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager, ...ROLEGROUPE.annotator]} },
  { path: 'tag/dataset-delete-management', component: DatasetDeleteManagementComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager, ...ROLEGROUPE.annotator]}},
  { path: 'tag/project/:projectName/export', component: ProjectExportComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager]} },
  { path: 'tag/project/:projectName/detail', component: ProjectDetailComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager, ...ROLEGROUPE.annotator]} },
  { path: 'tag/project/:projectName/lock', component: TaggingLockerComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager, ...ROLEGROUPE.annotator]} },
  { path: 'tag/project/:projectName/status-list', component: TaggingStatusListComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager, ...ROLEGROUPE.annotator]} },
  { path: 'tag/project/:projectName/dataset-viewer', component: DatasetViewerComponent, canActivate: [AuthorizationGuard], data: {allowdRoles: [...ROLEGROUPE.admin, ...ROLEGROUPE.manager]} },

  // Tools
  { path: 'tools/instagram-post-checker', component: InstagramPostCheckerComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule],
  providers: [
    TemplateStatusResolver,
    AuthorizationGuard
  ],
  declarations: []
})
export class AppRoutingModule { }
