import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpModule } from '@angular/http'
import { MatDialogConfig, MatExpansionModule, MatTreeModule, MatIconModule, MatProgressBarModule, MatCheckboxModule, MatCardModule } from '@angular/material'
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MAT_LABEL_GLOBAL_OPTIONS,
  MatAutocompleteModule,
  MatButtonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatInputModule,
  MatNativeDateModule,
  MatSelectModule
} from '@angular/material'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NG_SELECT_DEFAULT_CONFIG, NgSelectModule } from '@ng-select/ng-select'
import { AgGridModule } from 'ag-grid-angular'
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger'
import { WebStorageModule } from 'ngx-store'
import { AppRoutingModule, routes } from 'src/app/app-routing.module'
import { AppComponent } from 'src/app/app.component'
import { BboxComponent } from 'src/app/component/bbox/bbox.component'
import { SubclassSelectionComponent } from 'src/app/component/subclass-selection/subclass-selection.component'
import { TemplateGroupDataInputComponent } from 'src/app/component/template-group-data-input/template-group-data-input.component'
import { ColorComponent } from 'src/app/component/color/color.component'
import { DialogComponent } from 'src/app/component/dialog/dialog.component'
import { FormatSelectionComponent } from 'src/app/component/format-selection/format-selection.component'
import { HeaderComponent } from 'src/app/component/header/header.component'
import { OverlayComponent } from 'src/app/component/overlay/overlay.component'
import { SnackBarComponent } from 'src/app/component/snack-bar/snack-bar.component'
import { UserInfoComponent } from 'src/app/component/user-info/user-info.component'
import { ColorPickerModule } from 'src/app/module/color-picker/color-picker.module'
import { EndpointConfig } from 'src/app/config/endpoint-config'
import { OverlayDirective } from 'src/app/directive/overlay/overlay.directive'
import { KeyValueFilterPipe } from 'src/app/filter/key-value-filter.pipe'
import { DialogService } from 'src/app/service/dialog-service'
import { RouterService } from 'src/app/service/router-service'
import { DefinitionService } from 'src/app/service/server/definition-service'
import { TaggingApiService } from 'src/app/service/server/tag-service'
import { ProjectConvertService } from 'src/app/service/project/project-convert-service'
import { AuthorizationTokenInterceptor, UAC } from 'src/app/service/user/uac'
import { AgGridAttachComponent, AgGridColorSelectorComponent, AgGridRemoveComponent, AgGridSelectEditor, AgGridFlagBtn } from 'src/app/utils/attribute-table'
import { HttpHelper } from 'src/app/utils/http-helper'
import { environment } from 'src/environments/environment'
import { ZoomComponent } from './component/zoom/zoom.component'
import { CachingInterceptor } from './utils/http-helper'
import { TreeModule } from 'angular-tree-component';
import { ProjectApiService } from './service/server/project-api-service';
import { TaggingGroupSelectionComponent } from './component/tagging-group-selection/tagging-group-selection.component'

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthorizationTokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CachingInterceptor,
    multi: true
  }
]

const entryComponents = [
  OverlayComponent,
  DialogComponent,
  SnackBarComponent,
  SubclassSelectionComponent,
  FormatSelectionComponent,
  TemplateGroupDataInputComponent,
  TaggingGroupSelectionComponent]

const agGridCustomComponents = [
  AgGridAttachComponent,
  AgGridColorSelectorComponent,
  AgGridRemoveComponent,
  AgGridFlagBtn,
  AgGridSelectEditor]

@NgModule({
  declarations: [
    AppComponent,
    OverlayComponent,
    OverlayDirective,

    BboxComponent,
    ColorComponent,
    KeyValueFilterPipe,
    ZoomComponent,

    // dialog
    DialogComponent,
    SubclassSelectionComponent,
    FormatSelectionComponent,
    TemplateGroupDataInputComponent,
    TaggingGroupSelectionComponent,

    // snack bar
    SnackBarComponent,

    // -- layout
    HeaderComponent,
    UserInfoComponent
  ]
    .concat(routes.filter(def => !!def.component).map(def => def.component))
    .concat(<any[]>agGridCustomComponents)
    .concat(entryComponents),
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    HttpClientModule,
    BrowserAnimationsModule,
    ColorPickerModule,
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.INFO : NgxLoggerLevel.TRACE
    }),

    // --- material section.
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTreeModule,
    MatCardModule,
    MatIconModule,
    MatProgressBarModule,
    MatCheckboxModule,

    TreeModule.forRoot(),

    NgSelectModule,

    // --- ag-grid section.
    AgGridModule.withComponents(agGridCustomComponents),

    WebStorageModule
  ],
  providers: [
    // material2
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: (() => {
        var config = new MatDialogConfig()
        // var config = <any>{}
        config.disableClose = true
        return config
      })()
    },
    { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'never' } },
    {
      provide: NG_SELECT_DEFAULT_CONFIG,
      useValue: {
        notFoundText: 'Custom not found'
      }
    },

    EndpointConfig,
    UAC,
    httpInterceptorProviders,

    DefinitionService,
    TaggingApiService,
    ProjectApiService,
    ProjectConvertService,
    DialogService,
    RouterService,
    HttpHelper
  ],
  entryComponents: entryComponents,
  bootstrap: [AppComponent]
})
export class AppModule { }
