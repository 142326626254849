import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UAC } from 'src/app/service/user/uac';
import { Router } from '@angular/router';


@Component({
  'selector': 'app-user-info',
  'templateUrl': './user-info.component.html'
})
export class UserInfoComponent implements OnInit {

  onclick = false

  constructor(
    private router: Router,
    private uac: UAC
  ) {

  }

  ngOnInit() {
  }

  signIn() {
    this.onclick = false
    this.router.navigate(['/tag/login'])
  }

  signOut() {
    this.uac.logout()
    this.onclick = false
    this.router.navigate(['/tag/login'])
  }
}
