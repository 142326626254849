import { EventEmitter } from "@angular/core";

export interface CursorPosition {
  x: number
  y: number
}

export interface SearchFormDefinition {
  [key: string]: {
    type: StringConstructor | ArrayConstructor | DateConstructor,
    predicate?: Function
  };
}

export class SearchPageUtils {

  static sep = ','

  static getDefaultSearchForm(definitions: SearchFormDefinition) {

    var conditions: any = {};
    for (let key in definitions) {
      conditions[key] = definitions[key].type === Array
        ? []
        : null
    }
    return conditions
  }

  static queryParamToConditions(definitions: SearchFormDefinition, params: { [key: string]: string }) {
    var conditions: any = {};
    for (let key in definitions) {
      let def = definitions[key]
      if (params[key]) {
        conditions[key] = def.type === Array
          ? CommonUtils.replaceToSepFromUrlsep(params[key])
          : params[key]
      }
    }
    return conditions;
  }

  static searchFormToConditions(definitions: SearchFormDefinition, form: { [key: string]: any }): { [key: string]: string } {
    var conditions: any = {};

    for (let key in definitions) {
      let def = definitions[key]
      if (form[key] == null || form[key] == undefined) {
        continue
      }

      if (!def.predicate || def.predicate(form[key])) {
        conditions[key] = def.type === Array
          ? form[key].join(SearchPageUtils.sep)
          : def.type === Date
            ? CommonUtils.toStringFromDate(form[key])
            : form[key]
      }
    }
    return conditions;
  }

  static setSearchForm(definitions: SearchFormDefinition, conditions: { [key: string]: any }, form: { [key: string]: any }) {

    for (let key in definitions) {
      let def = definitions[key]
      if (conditions[key]) {
        form[key] = def.type === Array
          ? conditions[key].split(SearchPageUtils.sep)
          : def.type === Date
            ? CommonUtils.toDateFromString(conditions[key])
            : conditions[key]
      }
    }
  }
}

export class CommonUtils {

  static isAvailableFileApi(): boolean {
    return !!FileReader;
  }

  static floatFormat(number, n) {
    var _pow = Math.pow(10, n);
    return Math.round(number * _pow) / _pow;
  }

  static isEmpty(val) {
    if (val instanceof Number) {
      return val == null || val == 0;
    }
    if (val instanceof String) {
      return val == null || val == '';
    }
    if (val instanceof Array) {
      return val.length == 0;
    }
    return val == null;
  }

  static toDateFromString(val: String) {
    // yyyyMMdd形式の日付文字列をDate型に変換して返却する。
    const _date = val.substr(0, 4) + '/' + val.substr(4, 2) + '/' + val.substr(6)
    return new Date(_date);
  }

  static toStringFromDate(val: Date) {
    // Date型の値をyyyyMMdd形式の文字列に変換して返却する。
    return val.getFullYear() + ('0' + (val.getMonth() + 1)).slice(-2) + ('0' + val.getDate()).slice(-2);
  }

  static replaceToSepFromUrlsep(val: string) {
    // 文字列内のURLエンコードされた' '（半角スペース）を、全て' 'に変換する。
    const sep = ' ';
    var regExp = new RegExp(encodeURI(sep), 'g')
    return val.replace(regExp, sep)
  }

  static toNamedValue(val: string): { name: string, value: string } {
    return {
      name: val,
      value: val
    }
  }


  static getCursorNotificator(emmiter: EventEmitter<CursorPosition>) {
    return ($event: MouseEvent) => {
      let ele = (<HTMLElement>$event.currentTarget)

      let frameOffset = ele.getBoundingClientRect();

      let scrollX = window.scrollX;
      let scrollY = window.scrollY;

      let p = {
        x: Math.min(Math.max(Math.round($event.pageX - scrollX - frameOffset.left), 0), Math.round(frameOffset.width)),
        y: Math.min(Math.max(Math.round($event.pageY - scrollY - frameOffset.top), 0), Math.round(frameOffset.height))
      };

      emmiter.emit({
        x: p.x / frameOffset.width,
        y: p.y / frameOffset.height
      });
    }
  }

  static toMillFromS(s: number): number {
    s *= 1000;
    return Math.floor(s);
  }

  static parseStrToBoolean(str) {
    return (str == 'true')
      ? true
      : (str == 'false')
        ? false
        : str
  }

  static splitToSubset<T>(target: T[], splitSize: number): T[][] {

    let ret = <T[][]>[]
    for (var i = 0; i < Math.ceil(target.length / splitSize); i++) {
      var j = i * splitSize
      ret.push(target.slice(j, j + splitSize));
    }
    return ret
  }
}
