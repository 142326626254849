import {
  Directive,
  ElementRef,
  ViewContainerRef,
  Input,
  ComponentFactoryResolver,
  OnChanges,
  SimpleChanges,
  ComponentRef
} from "@angular/core";
import { OverlayComponent } from "src/app/component/overlay/overlay.component";

@Directive({
  selector: "[appOverlay]"
})
export class OverlayDirective implements OnChanges {
  @Input("show")
  show: boolean;

  overlayCompRef: ComponentRef<OverlayComponent>;

  constructor(
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      OverlayComponent
    );
    this.overlayCompRef = this.viewContainer.createComponent(componentFactory);
    this.overlayCompRef.instance.isShow = this.show;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.overlayCompRef.instance.isShow = changes.show.currentValue
  }
}
