import { Component, OnInit, ElementRef, Input, Output, ViewChild, SimpleChanges, EventEmitter, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { CommonUtils, CursorPosition } from '../../utils/common';

declare var JBA: any;

export interface BBoxPosition {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  meta?: any;
}

export interface BBoxEvent {
  event: string
  bbox: { id: string, x1: number, x2: number, y1: number, y2: number, meta?: any }
}

@Component({
  selector: 'app-bbox',
  templateUrl: './bbox.component.html',
  styleUrls: ['./bbox.component.css']
})
export class BboxComponent implements OnInit, OnDestroy {

  @ViewChild('imageTag') imageTag: ElementRef;
  @ViewChild('bboxContainer') bboxContainer: ElementRef;

  @Input('imgPath') imgPath: string;

  @Output() bboxEvent = new EventEmitter<BBoxEvent>();
  @Output() onLoadedImageEvent = new EventEmitter<boolean>();
  @Output() cursorPositionEvent = new EventEmitter<CursorPosition>();

  private image: HTMLImageElement;
  private bboxAnnotator: any = null;

  private cursorNotificator: ($event: MouseEvent) => void;
  unsubscriptions: Subscription[] = []

  constructor() {
  }

  ngOnInit() {
    this.image = this.imageTag.nativeElement
    var width, height;

    this.onLoadedImageEvent.emit(false)
    this.image.onload = (e) => {
      width = this.image.width;
      height = this.image.height;

      var div = this.bboxContainer.nativeElement;
      div.style.position = 'relative';
      div.style.top = '0';
      div.style.left = '0';
      div.style.width = width + 'px';
      div.style.height = height + 'px';

      this.cursorNotificator = CommonUtils.getCursorNotificator(this.cursorPositionEvent)
      this.unsubscriptions = [
        fromEvent<MouseEvent>(div, 'mousemove')
          .subscribe(
            (evt) => {
              this.cursorNotificator(evt)
            })]

      if (!this.bboxAnnotator) {
        this.bboxAnnotator = new JBA.BBoxAnnotator(div, (event, bbox) => {

          this.bboxEvent.emit({
            event: event,
            bbox: bbox
          })

        },
          {
            labelView: (meta) => {
              var span = document.createElement('span');
              span.className = 'bbox-caption'
              span.style.backgroundColor = 'rgb(127, 255, 127)';
              span.style.color = 'black';
              span.style.fontSize = '12px';
              span.style.whiteSpace = 'nowrap';
              span.style.marginLeft = '5px';

              if (meta) {
                var itemName = meta;
                // var confidence = (meta.item.confidence ? ':' + this.floatFormat(meta.item.confidence, 2) + '' : '');
                var confidence = ''
                var text = `${itemName}${confidence}`
                span.innerText = text
              }
              return span
            }
          })

      }
      this.onLoadedImageEvent.emit(true)
    }
  }

  addBBox(p: BBoxPosition, isSlient: boolean): string {
    var bbox = new JBA.BBox(p.x1, p.y1, p.x2, p.y2);
    bbox.meta = p.meta;
    this.bboxAnnotator.addBBox(bbox, isSlient);
    return bbox.id
  }

  setMetadata(bboxId, meta: any) {
    this.bboxAnnotator.setMetaBBox(bboxId, meta);
  }

  removeBBox(bboxId: string) {
    this.bboxAnnotator.removeBBox(bboxId);
  }

  undo() {
    this.bboxAnnotator.undo();
  }

  reset() {
    this.bboxAnnotator.reset()
  }

  doHighlight(bboxId) {
    this.bboxAnnotator.doHighlight(bboxId)
  }

  ngOnDestroy(): void {
    this.unsubscriptions.forEach(x => {
      x.unsubscribe()
    })
  }
}
