import { Component, Inject, OnInit, Testability } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";


export interface TaggingGroupSelectionModel {
    taggingGroupSelection: TaggingGroupSelection[]
}

export interface TaggingGroupSelection {
    name: string,
    isCurrent: boolean
}

export interface TaggingGroupSelectionData {
    ok: boolean,
    selectedTaggingGroupName: string
}

@Component({
    selector: 'app-taggingGroup-selection',
    templateUrl: './tagging-group-selection.component.html',
    styleUrls: ['./tagging-group-selection.component.css']
})
export class TaggingGroupSelectionComponent implements OnInit {

    taggingGroupSelection: TaggingGroupSelection[]
    selectedTaggingGroupName: string

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: TaggingGroupSelectionModel,
        public dialogRef: MatDialogRef<TaggingGroupSelectionModel>
    ) { }

    ngOnInit() {
        this.taggingGroupSelection = this.data.taggingGroupSelection
        let selectedTaggingGroup = this.taggingGroupSelection.find(lockLevel => lockLevel.isCurrent)
        this.selectedTaggingGroupName = selectedTaggingGroup ? selectedTaggingGroup.name : ''
    }

    ok() {
        this.dialogRef.close({
            ok: true,
            selectedTaggingGroupName: this.selectedTaggingGroupName
        })
    }

    cancel() {
        this.dialogRef.close({
            ok: false,
            selectedTaggingGroupName: null
        })
    }
}