import { Component, OnInit } from '@angular/core';
import { WebTaggingComponent } from '../web-tagging/web-tagging.component'
import { TemplateGroupObject, TemplateDataset, TemplateGroupDataset, TemplateObject } from 'src/app/model/template/template';
import { Observable } from 'rxjs';
import { map, mergeMap, finalize, filter, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { DatasetUpdateResponce } from 'src/app/model/dataset/dataset';
import { TemplateStatus } from 'src/app/model/template/templateStatus';

@Component({
  selector: 'app-web-tagging-for-template-group',
  templateUrl: '../web-tagging/web-tagging.component.html',
  styleUrls: ['../web-tagging/web-tagging.component.css']
})
export class WebTaggingForTemplateGroupComponent extends WebTaggingComponent {

  private templateGroupId: string
  private templateGroupDataset: TemplateGroupDataset[]

  private templateStatuses: TemplateStatus[]

  ngOnInit() {
    this.dialog.loadingShow()

    this.templateGroupId = this.route.snapshot.paramMap.get('templateGroupId')
    this.getTemplateGroupDataset(this.templateGroupId)
      .pipe(
        mergeMap(templateGroupObject => {
          this.projectName = templateGroupObject.projectName
          return this.getProject(this.projectName)
        }),
        finalize(() => this.dialog.loadingHide())
      )
      .subscribe(project => {
        this.project = project
        this.taggingColumns = this.projectConvertService.toTaggingColumnWithDefinition(project)
        let _resetScrollColumn = this.taggingColumns.length > 1
          ? this.taggingColumns.filter((taggingColumn) => taggingColumn.taggingLevel == 'item' && taggingColumn.isVisible && taggingColumn.sort == 2)[0]
          : this.taggingColumns.filter((taggingColumn) => taggingColumn.taggingLevel == 'item' && taggingColumn.isVisible)[0]

        this.resetScrollProperty = _resetScrollColumn.isArray
          ? `${_resetScrollColumn.property}.0`
          : _resetScrollColumn.property

        this.initAttributeTable()

        this.getNextImage()
          .subscribe(val => {
            this.selectImage(val)
            this.setErrorStatus()
          })
      })
  }

  getTemplateGroupDataset(templateGroupId) {
    var url = this.endpointConfig.riaApi.templates.json.group.templateGroupId.getUrl(templateGroupId)
    return this.http.get<TemplateGroupObject>(url)
  }

  getNextImage(): Observable<TemplateDataset> {
    let user = this.uac.getUser()
    // datasetsを最新の状態に更新
    let nextImage = this.getTemplateGroupDataset(this.templateGroupId)
      .pipe(
        map(templateGroupObject => {
          this.templateGroupDataset = templateGroupObject.datasets
          this.datasets = templateGroupObject.datasets.map(x => x.dataset)
          this.templateStatuses = templateGroupObject.datasets.map(x => x.templateStatus)

          this.datasets.forEach(dataset => {
            this.addClientPath(dataset)
            dataset.imageTags.forEach(x => {
              let imageDefault = this.createDefaultTag('image')
              Object.keys(imageDefault).forEach(key => {
                if (!(key in x)) {
                  x[key] = imageDefault[key]
                }
              })
            })
            dataset.tags.forEach(x => {
              let itemDefault = this.createDefaultTag('item')
              Object.keys(itemDefault).forEach(key => {
                if (!(key in x)) {
                  x[key] = itemDefault[key]
                }
              })
            })
          })

          // 初期表示の場合、spanを設定し、NextImageの場合は、現状のOffsetを設定する。
          // ※ImageListの画像表示枚数、スクロール位置を保持するため。
          this.displayOffset = this.displayOffset == 0 ? this.displaySpan : this.displayOffset
          this.displayDatasetList = this.datasets.slice(0, this.displayOffset)

          for (let dataset of this.datasets) {

            if (!dataset.tmp.isTagging) {
              if (!dataset.tmp.editing || dataset.tmp.editing.editor == user.userId) {
                return dataset
              }
            }
          }
          this.isInitialLoaded = true
          return null
        })
      )
    return nextImage
  }

  selectImage(selectImage: TemplateDataset) {
    // templateが画像単位で存在するため、画像選択時にtemplateStatusをセットする。
    if (selectImage) {
      this.templateStatus = this.templateStatuses
        .filter(templateStatus => templateStatus.templateId == selectImage.templateId)[0]
    } else {
      this.templateStatus = null
    }
    super.selectImage(selectImage)
  }

  setErrorStatus() {

    this.errorImageList = {}
    for (let templateStatus of this.templateStatuses) {

      if (templateStatus.status == 'error' && templateStatus.importErrors.length > 0 && 'errors' in templateStatus.importErrors[0]) {
        templateStatus.importErrors[0].errors.forEach(e => {
          var errorImage = this.datasets[e.path.split('.')[1]]
          errorImage.tmp.isTagging = false

          if (!this.errorImageList[errorImage.src]) {
            this.errorImageList[errorImage.src] = []
          }
          this.errorImageList[errorImage.src].push(Number(e.path.split('.')[3]) + 1 + '行目: ' + e.message)
        })
      }
    }

    if (this.taggingImage && this.errorImageList[this.taggingImage.src]) {
      this.openErrorSnackBar(this.errorImageList[this.taggingImage.src])
    }
  }

  doSubmit() {
    this.resetAttachAndColor()
    this.dialog
      .confirm('Submit', WebTaggingComponent.SUBMIT_CONFIRM(this.templateStatuses[0]))
      .pipe(
        filter(x => x),
        tap(() => this.dialog.loadingShow()),
        mergeMap(x => {
          // 不要なプロパティの削除
          let datasets = <TemplateDataset[]>_.cloneDeep(this.datasets)

          let itemTargetKeys = this.taggingColumns
            .filter(x => x.taggingLevel == 'item' && x.isArray)
            .map(x => x.property)

          let imageTargetKeys = this.taggingColumns
            .filter(x => x.taggingLevel == 'image' && x.isArray)
            .map(x => x.property)

          for (let dataset of datasets) {
            delete dataset['uUser']
            delete dataset['uDate']

            for (let tag of dataset.tags) {
              delete tag.bboxId
              delete tag.author
              delete tag.aiIndex

              itemTargetKeys.forEach(key => {
                tag[key] = tag[key].filter(x => !!x)
              })
            }

            for (let imageTag of dataset.imageTags) {
              imageTargetKeys.forEach(key => {
                imageTag[key] = imageTag[key].filter(x => !!x)
              })
            }
          }
          var reqData = {}
          reqData['batch'] = datasets
          this.logger.debug('SUBMIT', reqData)

          return this.http.put<DatasetUpdateResponce>(this.endpointConfig.riaApi.project.projectName.datasets.putUrl(this.projectName), reqData)
        }),
        finalize(() => this.dialog.loadingHide())
      )
      .subscribe(
        () => this.router.navigate([`tag/project/${this.projectName}/status-list`]),
        (res: HttpErrorResponse) => {
          if (res.status == 400) {
            var messages = []
            // importErrorsにエラーが存在するかチェック
            if ('templateStatuses' in res.error && res.error['templateStatuses'].length > 0) {
              res.error['templateStatuses'].forEach((templateStatus: TemplateStatus) => {
                // importErrorsにエラーが存在するかチェック
                if (templateStatus.status == 'error' && templateStatus.importErrors.length > 0 && 'errors' in templateStatus.importErrors[0]) {
                  templateStatus.importErrors[0].errors.forEach(e => {
                    messages.push(e.path + ': ' + e.message)
                  })
                }
              })
            } else {
              res.error['errors'].forEach(e => {
                messages.push(e.path + ': ' + e.message)
              })
            }
            this.dialog.error('Error', messages.join('<br>')).subscribe(x => {
              this.ngOnInit()
            })
          } else {
            this.httpHelper.getDefaultErrorHandler()(res)
          }
        }
      )
  }
}
