import { Component, OnInit } from '@angular/core';
import { UAC } from 'src/app/service/user/uac';
import { CommonUtils } from 'src/app/utils/common';
import { Router } from '@angular/router';
import { DefinitionService } from 'src/app/service/server/definition-service';

@Component({
    selector: 'tag-home',
    templateUrl: './tagging.component.html',
    styleUrls: ['./tagging.component.css']
})
export class TaggingHomeComponent implements OnInit {

    constructor(
        private router: Router,
    ) {

    }

    ngOnInit() {
    }

    navigateProgressCheck() {
        var fromDate = new Date()
        fromDate.setMonth(fromDate.getMonth() - 3)
        var toDate = new Date();

        var fromDateYmd = CommonUtils.toStringFromDate(fromDate);
        var toDateYmd = CommonUtils.toStringFromDate(toDate);
        this.router.navigate(['tag/progress-check'], {
            queryParams: {
                fromDate: fromDateYmd,
                toDate: toDateYmd
            }
        })
    }

    navigateStatusList() {
        var fromDate = new Date()
        fromDate.setDate(fromDate.getDate() - 7)
        var toDate = new Date();

        var fromDateYmd = CommonUtils.toStringFromDate(fromDate);
        var toDateYmd = CommonUtils.toStringFromDate(toDate);
        this.router.navigate(['tag/status-list'], {
            queryParams: {
                fromDate: fromDateYmd, toDate: toDateYmd
            }
        })
    }
}
