import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { TemplateStatusList, TemplateStatus } from 'src/app/model/template/templateStatus';
import { first, map } from "rxjs/operators";
import { EndpointConfig } from "src/app/config/endpoint-config";
import { CommonUtils } from 'src/app/utils/common';
import { TemplateGroupList } from "src/app/model/template/template";


@Injectable()
export class TaggingApiService {

  transformTemplate = (x: TemplateStatus) => {
    x.cDate = CommonUtils.toMillFromS(x.cDate)
    x.iDate = CommonUtils.toMillFromS(x.iDate)

    x.importErrors.forEach((data) => {
      data.iDate = CommonUtils.toMillFromS(data.iDate)
    });

    return x
  }

  constructor(
    private http: HttpClient,
    private endpointConfig: EndpointConfig,
  ) {

  }

  public createTemplateStatus(param: {
    'collection': string,
    'brand': string[]
  }, format: 'excel' | 'json') {
    return this.http.post<TemplateStatus>(this.endpointConfig.riaApi.tags.templates.getUrl(format), param)
      .pipe(
        first(),
        map(this.transformTemplate)
      )
  }

  public getRunwayTemplateStatus(templateId: string): Observable<TemplateStatus> {
    return this.http.get<TemplateStatus>(this.endpointConfig.riaApi.templates.getUrl(templateId))
      .pipe(
        first(),
        map(this.transformTemplate)
      )
  }

  public getRunwayTemplateStatusList(projectName: string, query: any): Observable<TemplateStatusList> {
    return this.http.get<TemplateStatusList>(this.endpointConfig.riaApi.project.projectName.templates.getUrl(projectName), {
      params: query
    }).pipe(
      first(),
      map(x => {
        x.statuses = x.statuses.map(this.transformTemplate)
        return x
      })
    )
  }

  public getTemplateGroupList(projectName: string): Observable<TemplateGroupList> {
    return this.http.get<TemplateGroupList>(this.endpointConfig.riaApi.project.projectName.templates.group.getUrl(projectName))
      .pipe(
        map(x => {
          x.templateGroups.forEach(templateGroup => {
            templateGroup.cDate = CommonUtils.toMillFromS(templateGroup.cDate)
          })
          return x
        })
      )
  }
}
