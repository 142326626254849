import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material";

export interface FormatSelectionResult {
    ok: boolean;
    format: 'excel' | 'json';
}


@Component({
    selector: 'format-selection',
    templateUrl: './format-selection.component.html',
})
export class FormatSelectionComponent {

    formats = [
        'json', 'excel'
    ]
    selectedFormat = 'json'

    constructor(
        public dialogRef: MatDialogRef<FormatSelectionComponent, FormatSelectionResult>
    ) {

    }

    ok() {
        this.dialogRef.close({
            ok: true,
            format: <'json' | 'excel'>this.selectedFormat
        })
    }

    cancel() {
        this.dialogRef.close({
            ok: false,
            format: null
        })
    }


}