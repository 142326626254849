import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap, tap, finalize, filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { EndpointConfig } from 'src/app/config/endpoint-config';
import { Project } from 'src/app/model/project/project';
import { DialogService } from 'src/app/service/dialog-service';
import { HttpHelper } from '../../utils/http-helper';
import { SubclassSelectionStatus } from 'src/app/component/subclass-selection/subclass-selection.component';

@Component({
    selector: 'app-lock',
    templateUrl: './lock.component.html',
    styleUrls: ['./lock.component.css']
})

export class TaggingLockerComponent {

    static TEMPLATE_CONFIRM = (taggingGroup, selectedCategory, selectedSubclasses) => {

        if (taggingGroup == 'category') {
            return `Category: ${selectedCategory}<br>
            に対してタグ付を開始しますか？`

        } else if (taggingGroup == 'subclass') {
            return `Category: ${selectedCategory} <br>
            Subclass: ${selectedSubclasses}<br>
            に対してタグ付を開始しますか？`
        }

    }


    public projectName: string = ''
    public project: Project
    public category: any[]
    public subclass: string[]
    private taggingGroup: string = ''
    private categoryList: string[] = []
    private taggingPreset: string[] = []
    private targetSubclass: string[] = []
    private subclassStatusList: SubclassSelectionStatus[] = []
    private selectedSubclasses: string[] = []
    private selectedCategory: string = ''


    constructor(

        private http: HttpClient,
        private logger: NGXLogger,
        private endpointConfig: EndpointConfig,
        private dialog: DialogService,
        private router: Router,
        private route: ActivatedRoute,
        private httpHelper: HttpHelper,
        private dialogService: DialogService
    ) { }


    ngOnInit() {

        this.dialog.loadingShow()

        this.projectName = this.route.snapshot.paramMap.get('projectName')

        this.getProject(this.projectName)
            .pipe(finalize(() => this.dialog.loadingHide()))
            .subscribe((project) => {

                this.taggingPreset = project.taggingPreset
                this.category = project.datasetInfo.category
                this.taggingGroup = project.taggingGroup
                this.categoryList = []
                for (let categoryElement of this.category) {
                    this.categoryList.push(categoryElement.category)
                }
            })

    }

    getProject(projectName: string) {
        var url = this.endpointConfig.riaApi.project.projectName.getUrl(projectName)
        let options = { params: new HttpParams().set('includeDatasetInfo', 'true') };
        return this.http.get<Project>(url, options)
    }



    // ダイアログ画面への処理
    displaySubclassSelection(selectedCategory: string) {

        this.subclassStatusList = []
        this.selectedCategory = selectedCategory

        for (let categoryElement of this.category) {
            if (categoryElement.category == this.selectedCategory) {
                this.targetSubclass = categoryElement.subclass.sort()
                break
            }
        }

        for (let target of this.targetSubclass) {
            this.subclassStatusList.push({
                name: target,
                isSelected: this.selectedSubclasses.indexOf(target) >= 0
            })
        }

        this.dialogService.subclassSelection({
            selectedSubclass: this.subclassStatusList,
            taggingPreset: this.taggingPreset
        }).subscribe(
            (selectedSubclasses: string[]) => {
                if (selectedSubclasses == undefined) {
                    selectedSubclasses = []
                }
                this.selectedSubclasses = selectedSubclasses
            })
    }

    changeCategory() {
        this.selectedSubclasses = [];
    }

    unselect(subclass: string) {
        var index = this.selectedSubclasses.indexOf(subclass);
        this.selectedSubclasses.splice(index, 1)
    }


    disabledLockButton() {
        if (this.taggingGroup == 'category' && this.selectedCategory == '') {
            return true
        } else if (this.taggingGroup == 'subclass' && this.selectedSubclasses.length == 0) {
            return true
        }
    }


    lock() {
        this.dialog.confirm('Lock', TaggingLockerComponent.TEMPLATE_CONFIRM(this.taggingGroup, this.selectedCategory, this.selectedSubclasses))
            .pipe(
                filter(x => x),
                tap(() => this.dialog.loadingShow()),
                mergeMap(x => {

                    let reqData = {}
                    if (this.taggingGroup == 'category') {

                        reqData['category'] = this.selectedCategory
                        reqData['lockLevel'] = "category"
                        reqData['projectName'] = this.projectName

                    } else if (this.taggingGroup == 'subclass') {

                        reqData['category'] = this.selectedCategory
                        reqData['lockLevel'] = "subclass"
                        reqData['projectName'] = this.projectName
                        reqData['subclasses'] = this.selectedSubclasses

                    }
                    return this.http.post(this.endpointConfig.riaApi.templates.json.postUrl(), reqData)
                }),
                finalize(() => this.dialog.loadingHide())
            )
            .subscribe(
                (templateStatus) => {
                    let templateId = templateStatus['templates'][0]['templateId']
                    this.router.navigate(['tag/web-tagging/' + templateId])
                },
                (res: HttpErrorResponse) => {
                    if (res.status == 400 || res.status == 409) {
                        var messages = []
                        res.error.errors.forEach(e => {
                            messages.push(e.message)
                        })
                        this.dialog.error('Error', messages.join('<br>')).subscribe(x => {
                            this.ngOnInit()
                        })
                    } else {
                        this.httpHelper.getDefaultErrorHandler()(res)
                    }
                }
            )
    }

}
