import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { UAC } from 'src/app/service/user/uac';
import { EndpointConfig } from 'src/app/config/endpoint-config';
import { DialogService } from 'src/app/service/dialog-service';
import { ProjectApiService } from 'src/app/service/server/project-api-service';
import { RequestDeleteDatasetHistory } from 'src/app/model/request/requestDeleteDataset';


@Component({
  selector: 'app-dataset-delete-management',
  templateUrl: './dataset-delete-management.component.html',
  styleUrls: ['./dataset-delete-management.component.css']
})
export class DatasetDeleteManagementComponent implements OnInit {

  static STATUS_CHANGE_RESULT_MESSAGE = (args: {isError: boolean}) => {
    if (args.isError) {
      return `削除依頼履歴のステータス変更が失敗しました。`
    } else {
      return `削除依頼履歴のステータスが変更されました。`
    }
  }


  constructor(
    public http: HttpClient,
    private router: Router,
    private uac: UAC,
    private endpointConfig: EndpointConfig,
    private dialog: DialogService,
    private projectApiService: ProjectApiService,
  ) { }

  private histories: RequestDeleteDatasetHistory;

  ngOnInit() {
    this.dialog.loadingShow()

    let getRequestDeleteDatasetQueryParams = new HttpParams()
      .append('isCompleted', 'false')

    this.http.get(
      this.endpointConfig.riaApi.request.deleteDataset.getUrl(),
      { params: getRequestDeleteDatasetQueryParams }
    )
    .pipe(
      finalize(() => this.dialog.loadingHide())
    ).subscribe(
      (data: RequestDeleteDatasetHistory) => {
        this.histories = data
      }
    )
  }

  statusChange(historyId: number, status: { isCompleted: boolean }) {
    this.dialog.loadingShow()

    this.http.put(
      this.endpointConfig.riaApi.request.deleteDataset.putUrl(historyId),
      { isCompleted: status.isCompleted }
    )
    .pipe(
      finalize(() => this.dialog.loadingHide())
    ).subscribe(
      (res) => {
        this.dialog.info(
          "Status Change",
          DatasetDeleteManagementComponent.STATUS_CHANGE_RESULT_MESSAGE({isError: false})
        )
      },
      (res: HttpErrorResponse) => {
        this.dialog.info(
          "Status Change",
          DatasetDeleteManagementComponent.STATUS_CHANGE_RESULT_MESSAGE({isError: true})
        )
      }
    )
  }
}
