import { Component } from '@angular/core';
import { UAC } from 'src/app/service/user/uac';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { DialogService } from 'src/app/service/dialog-service';
import { HttpHelper } from 'src/app/utils/http-helper';


@Component({
    selector: 'tag-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class TaggingLoginComponent {

    private userId: string = ''
    private password: string = ''

    private isRetention: boolean = false;
    private errorMsg: string = '';

    /* @ngInject */
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private uac: UAC,
        private dialogService: DialogService,
        private httpHelper: HttpHelper
    ) {
    }

    ngOnInit(){
        const token = this.route.snapshot.queryParams.token;
        const error = this.route.snapshot.queryParams.error;
        const next = this.route.snapshot.queryParams.next ? decodeURIComponent(this.route.snapshot.queryParams.next) : '';
        
        if(error){
            alert(error);
            this.errorMsg = error;
            return;
        }

        if(token){
            this.uac.setUser({
                userId: this.route.snapshot.queryParams.userId,
                token: token,
                tokenExpirationDate: this.route.snapshot.queryParams.tokenExpirationDate,
                role: this.route.snapshot.queryParams.role,
            }, true)
            if(next){
                this.router.navigateByUrl(next);
            }else{
                this.router.navigate(['tag/project-list']);
            }
        }else{
            if(this.uac.isLogged()){
                // アドレスバーから直接tag/loginへのアクセスがあり、パスパラメーターとしてtokenを
                // 受け取っていない場合に、ログイン済みユーザーに対して再度ログイン処理が流れるのを防ぐため
                this.router.navigate(['tag/project-list'])
                return
            }
            this.uac.logout();
            this.uac.sso(next);
        }
    }

    retry(){
        if(!this.errorMsg){
        // AzureADログインURLを直接GETすることはできないため
        // 画面リロードでパラメータを保持したまま再試行する
        window.location.reload()
        }else{
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload'
            this.router.navigate(['tag/login'])
        }
    }

    login() {
        this.dialogService.loadingShow()

        this.uac.login(
            this.userId,
            this.password
        ).pipe(
            finalize(() => this.dialogService.loadingHide())
        ).subscribe(
            (ret) => {
                this.uac.setUser(ret, this.isRetention)
                
                if (this.route.snapshot.queryParams.next) {
                    var _next = '/' + this.route.snapshot.queryParams.next.replace('%2F', '/')
                    this.router.navigateByUrl(_next)
                } else {
                    this.router.navigate(['tag/project-list'])
                }
            },
            this.httpHelper.getDefaultErrorHandler()
        )
    }
}