import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { DialogService } from 'src/app/service/dialog-service';
import { ProjectApiService } from 'src/app/service/server/project-api-service';
import { Project } from 'src/app/model/project/project';
import { finalize} from 'rxjs/operators';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements OnInit {

  constructor(
    private logger: NGXLogger,
    private dialog: DialogService,
    private projectApiService: ProjectApiService,
  ) { }

  public projects: Project[]
  public totalCount: number

  public filterForm = {
    projectName: '',
    description: '',
    taggingGroup: '',
    taggingPreset: '',
    cUser: ''
  };
  searchForm = {
    fromDate: null,
    toDate: null
  };
  private taggingGroups = ['category', 'subclass', 'none']

  ngOnInit() {
    this.dialog.loadingShow()
    this.projectApiService.getProjects()
      .pipe(finalize(() => this.dialog.loadingHide()))
      .subscribe((data) => {
        this.projects = data.projects
        this.totalCount = data.totalCount
      })
  }

  resetFilter() {
    this.filterForm = {
      projectName: '',
      description: '',
      taggingGroup: '',
      taggingPreset: '',
      cUser: ''
    }
  }
}
