export class ColorHelper {

  // ex. eventColorPicker = #fff
  static fromColorPicker(isClick: boolean, handler: ({code: str, isClick: boolean}) => void) {

    return (eventColorPicker) => {
      handler({
        code: eventColorPicker,
        isClick: isClick
      })
    }
  }
}