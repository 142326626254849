import * as _ from 'lodash';
import { Project } from 'src/app/model/project/project';
import { CommonUtils } from 'src/app/utils/common';
import { IsColumnFunc, ColDef } from 'ag-grid';
import { IsColumnFuncParams } from 'ag-grid/dist/lib/entities/colDef';

export class TaggingHelper {

  static createCellStyleFunction(val: boolean) {
    return val ? {
      "background-color": "gray"
    } : {
        "background-color": ""
      }
  }

  static deleteCellValue4DisabledPropertiesTarget(params: IsColumnFuncParams, project: Project) {
    if (!('tableControl' in project && 'disabledProperties' in project.tableControl)) {
      return params
    }

    _.flatten(
      project.tableControl.disabledProperties
        .filter(x => x.trigger.property == params.colDef.field)
        .filter(x => {
          let tmp = x.trigger.property.split('.')
          let triggerProperty = tmp[0]
          let triggerValue = tmp.length == 2
            ? params.data[triggerProperty][tmp[1]]
            : params.data[triggerProperty]

          return x.trigger.values.indexOf(triggerValue) >= 0
        })
        .map(x => x.target.properties)

    ).forEach(targetProperty => {
      let tmp = targetProperty.split('.')
      let _targetProperty = tmp[0]
      if (tmp.length == 2) {
        let i = tmp[1]
        params.data[_targetProperty][i] = null
      } else {
        params.data[_targetProperty] =  typeof params.data[_targetProperty] == 'boolean'
        ? false
        : null
      }
    });
    return params
  }

  static deleteCellValue4HiddenTagsTarget(params: IsColumnFuncParams, project: Project) {
    if (!('tableControl' in project && 'hiddenTags' in project.tableControl)) {
      return params
    }

    project.tableControl.hiddenTags
      .filter(x => x.trigger.property == params.colDef.field)
      .filter(x => {
        let tmp = x.trigger.property.split('.')
        let triggerProperty = tmp[0]
        let triggerValue = tmp.length == 2
          ? params.data[triggerProperty][tmp[1]]
          : params.data[triggerProperty]
        
        return x.trigger.values.indexOf(triggerValue) >= 0
      })
      .map(x => x.target)
      .forEach(target => {
          let tmp = target.property.split('.')
          let _targetProperty = tmp[0]
          if (tmp.length == 2) {
            let i = tmp[1]

            if (target.values.indexOf(params.data[_targetProperty][i]) >= 0) {
              params.data[_targetProperty][i] = null
            }
          } else {
            if (target.values.indexOf(params.data[_targetProperty]) >= 0) {
              params.data[_targetProperty] = null
            }
          }
      });
    return params
  }

  static addAutoInputTags(params: IsColumnFuncParams, project: Project) {
    if (!('tableControl' in project && 'autoInputTags' in project.tableControl)) {
      return params
    }

    _.flatten(
      project.tableControl.autoInputTags
        .filter(x => x.trigger.property == params.colDef.field)
        .filter(x => {
          let tmp = x.trigger.property.split('.')
          let triggerProperty = tmp[0]
          let triggerValue = tmp.length == 2
            ? params.data[triggerProperty][tmp[1]]
            : params.data[triggerProperty]

          return CommonUtils.parseStrToBoolean(x.trigger.value) == triggerValue
        })
        .map(x => x.targets)

    ).forEach(target => {
      let tmp = target.property.split('.')
      let _targetProperty = tmp[0]
      if (tmp.length == 2) {
        let i = tmp[1]
        params.data[_targetProperty][i] = CommonUtils.parseStrToBoolean(target.value)
      } else {
        params.data[_targetProperty] = CommonUtils.parseStrToBoolean(target.value)
      }
    });
    return params

  }

  static checkDisabledProperties(params: IsColumnFuncParams, project: Project): boolean {
    if (!('tableControl' in project && 'disabledProperties' in project.tableControl)) {
      return false
    }

    let _disabledProperties = _.flatten(
      project.tableControl.disabledProperties
        .filter(x => x.target.properties.indexOf(params.colDef.field) >= 0)
        .filter(x => {
          let tmp = x.trigger.property.split('.')
          let triggerProperty = tmp[0]
          let triggerValue = tmp.length == 2
            ? params.data[triggerProperty][tmp[1]]
            : params.data[triggerProperty]

          let triggerValues = x.trigger.values.map(val => CommonUtils.parseStrToBoolean(val))
          return triggerValues.indexOf(triggerValue) >= 0
        })
    )
    return _disabledProperties.length > 0
  }

  static excludeHiddenTags(params: IsColumnFuncParams, project: Project, optionValues: { name: string, value: string }[]) {

    let ret = {
      AgGridSelectEditorParam: {
        values: optionValues
      }
    }

    if (!('tableControl' in project && 'hiddenTags' in project.tableControl)) {
      return ret
    }

    project.tableControl.hiddenTags
      .filter(x => x.target.property == params.colDef.field)
      .filter(x => {
        let tmp = x.trigger.property.split('.')
        let triggerProperty = tmp[0]
        let triggerValue = tmp.length == 2
          ? params.data[triggerProperty][tmp[1]]
          : params.data[triggerProperty]

        let triggerValues = x.trigger.values.map(val => CommonUtils.parseStrToBoolean(val))

        return triggerValues.indexOf(triggerValue) >= 0
      })
      .map(x => {
        optionValues = optionValues.filter(optionVal => x.target.values.indexOf(optionVal['name']) == -1)
        ret.AgGridSelectEditorParam.values = optionValues
      })

    return ret
  }
}
