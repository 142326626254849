import { Component, Injectable, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
  } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TemplateStatus } from 'src/app/model/template/templateStatus';
import { DialogService } from 'src/app/service/dialog-service';
import { RouterService } from 'src/app/service/router-service';
import { TaggingApiService } from 'src/app/service/server/tag-service';
import { HttpHelper } from 'src/app/utils/http-helper';

@Injectable()
export class TemplateStatusResolver implements Resolve<TemplateStatus> {

    constructor(
        private taggingApiService: TaggingApiService,
        private dialog: DialogService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        var templateId = route.paramMap.get('templateId')
        this.dialog.loadingShow()
        return this.taggingApiService.getRunwayTemplateStatus(templateId)
            .pipe(
                finalize(() => this.dialog.loadingHide())
            )
    }
}


@Component({
    selector: 'tag-status-detail',
    templateUrl: './status-detail.component.html',
    styleUrls: ['./status-detail.component.css']
})
export class TaggingStatusDetailComponent implements OnInit {

    private status: TemplateStatus;
    private tabs: number[] = [];
    private activeTabKey: number;
    private errorDetail = { 'status': '', 'importError': {} };
    private previousUrl: string;
    private category: string;
    private subclasses: string[]
    private lockLevel: string = ''
    private datasetId: string = ''

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private routerService: RouterService,
        private httpHelper: HttpHelper
    ) {
    }

    ngOnInit() {

        var templateId = this.activatedRoute.snapshot.paramMap.get('templateId')
        // this.prevInstruction = this.activatedRoute.;
        this.activatedRoute.data.subscribe(
            (data) => {
                this.status = <TemplateStatus>data.status;
                this.tabs = this.status.importErrors.map(data => data.iDate);
                this.activeTabKey = this.tabs[0];
                this.lockLevel = this.status.lock.lockLevel
                this.datasetId =  this.status.lock.datasetId
            },
            (error) => {
                this.httpHelper.getDefaultErrorHandler()(error).subscribe(() => {
                    this.router.navigate(['tag/home'])
                })
            })
    }

    changeTab(tab) {
        this.activeTabKey = tab;
    }

    back() {
      if (this.routerService.getCurrentUrl() === this.routerService.getPreviousUrl()) {
        this.router.navigate(['tag/home'])
      } else {
        this.router.navigateByUrl(this.routerService.getPreviousUrl())
      }
    }
}
