import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, interval, Subscription, of, from, zip, noop } from 'rxjs';
import { CellValueChangedEvent, GridOptions, CellMouseOverEvent, CellMouseOutEvent, Column } from 'ag-grid';
import * as _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { catchError, filter, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { BboxComponent, BBoxEvent } from 'src/app/component/bbox/bbox.component';
import { SnackBarComponent } from 'src/app/component/snack-bar/snack-bar.component';
import { EndpointConfig } from 'src/app/config/endpoint-config';
import { Project, TagDefinition, TaggingColumn } from 'src/app/model/project/project';
import { DatasetUpdateResponce } from 'src/app/model/dataset/dataset';
import { TemplateDataset, TemplateObject, Tmp } from 'src/app/model/template/template';
import { TemplateStatus } from 'src/app/model/template/templateStatus';
import { OnlinePredictionResult } from 'src/app/model/common';
import { ColorPickerService } from 'src/app/module/color-picker/color-picker.service';
import { DialogService } from 'src/app/service/dialog-service';
import { RouterService } from 'src/app/service/router-service';
import { TaggingApiService } from 'src/app/service/server/tag-service';
import { UAC } from 'src/app/service/user/uac';
import { AgGridBtnComponentParam } from 'src/app/utils/attribute-table';
import { CommonUtils, CursorPosition } from 'src/app/utils/common';
import { ColorHelper } from './helpers/color-helper';
import { ProjectConvertService, TaggingColumnWithDefinition } from '../../service/project/project-convert-service';
import { HttpHelper } from '../../utils/http-helper';
import { TaggingHelper } from './helpers/tagging-helper';
import { IsColumnFuncParams } from 'ag-grid/dist/lib/entities/colDef';
import { NonAnonymizedAlertResult } from 'src/app/model/alert/nonAnonymized';
import { RequestDeleteDatasetResult } from 'src/app/model/request/requestDeleteDataset';


// import * as mockData from './mock-data.json';

function conditionalGuard<T>(predicate: (this: T) => boolean,
  logLevel: 'debug' | 'info' | 'error' | 'warn' = 'debug',
  logMessage: string = '') {

  return function (target: T, propertyKey: string, descriptor: PropertyDescriptor) {

    const method = descriptor.value
    descriptor.value = function () {
      if (predicate.apply(this, arguments)) {
        return method.apply(this, arguments);
      } else {
        let logger = this['logger'] ? this['logger'] : console
        logger[logLevel](`${propertyKey}:conditionalGuard apply early return from function. ${logMessage}`)
      }
    }
  }
}

const taggingStatusGuard = conditionalGuard<WebTaggingComponent>(function () {
  return ['created', 'error'].indexOf(this.templateStatus.status) > -1
})
const onlinePredictionGuard = conditionalGuard<WebTaggingComponent>(function () {
  return !!this.project.onlinePredictionUrl
}, 'warn', 'It has not specify online prediction url. so preload prediction canceled.')

@Component({
  selector: 'app-web-tagging',
  templateUrl: './web-tagging.component.html',
  styleUrls: ['./web-tagging.component.css']
})
export class WebTaggingComponent implements OnInit {
  static LAYOUT_MODE = {
    normal: {
      isShowBrandImages: true,
      brandImagesAreaHeight: '170px',
      annotationContainerHeight: 'calc(100% - 170px)'
    },
    fullscreen: {
      isShowBrandImages: false,
      brandImagesAreaHeight: '45px',
      annotationContainerHeight: 'calc(100% - 45px)'
    },
    viewer: {
      isShowBrandImages: true,
      brandImagesAreaHeight: 'calc(100% - 3px)',
      annotationContainerHeight: 'calc(100% - 170px)'
    }
  }

  static UNSELECTED_ITEM = (src, unSelectedRowList) => {
    return `タグ付け画像: ${src} <br>
            未選択行: ${unSelectedRowList.join(', ')}<br>
            アイテムを選択するか、対象行の削除をしてください。`
  }
  static SUBMIT_CONFIRM = (templateStatus: TemplateStatus) => {
    let lock = templateStatus.lock
    let messages = []

    messages.push(`Project: ${lock.projectName} <br>`)
    if ('category' in lock) {
      messages.push(`Category: ${lock.category} <br>`)
    }
    if ('subclasses' in lock) {
      messages.push(`Subclass: ${templateStatus.lock.subclasses.join(', ')} <br>`)
    }
    messages.push('上記のタグ付け結果を登録します。よろしいですか？<br>')
    messages.push(`<br>
    <strong>他の作業者はいませんか？<br>
    Submitを実行すると、本画面のデータはImport出来なくなります。<br>
    意図せず画面を開いてしまった場合、「Back」ボタンで戻って下さい。</strong>`)
    return messages.join('')
  }
  static BACK_CONFIRM = () => {
    return `前の画面へ戻ります。よろしいですか？`
  }
  static TAGGINGCOMPLETE = () => {
    return `タグ付が完了しています。タグ付を続ける場合は対象画像を選択してください。`
  }
  static ALERT_NON_ANONYMIZED_DATASETS = (alertedCount: number, alertFailedDatasetIds:Array<string>) => {
    const alertFailedMessage = alertFailedDatasetIds ? `以下データセットのアラート通知に失敗しました。<br>
                                IDをコピーして運用担当者へご連絡ください。<br>
                                ${alertFailedDatasetIds.join('<br>')}` : ''
    return `アラート済み非匿名化画像: ${alertedCount}枚<br>
            ${alertFailedMessage}`
  }
  static REQUEST_DELETE_DATASET = (requestCount: number, requestFailedDataset: Array<string>) => {
    const message = requestFailedDataset.length ? `以下データセットの削除依頼に失敗しました。<br>
                                IDをコピーして運用担当者へご連絡ください。<br>
                                ${requestFailedDataset.join('<br>')}` : ''
    return `削除依頼済み画像: ${requestCount}枚<br>
            ${message}`
  }

  @ViewChild(BboxComponent)
  private bboxComponent: BboxComponent

  @ViewChild('taggingImageElementWrapper') private taggingImageElementWrapper: ElementRef<HTMLDivElement>

  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public logger: NGXLogger,
    public dialog: DialogService,
    public routerService: RouterService,

    public cpService: ColorPickerService,
    public taggingApiService: TaggingApiService,
    public projectConvertService: ProjectConvertService,
    public endpointConfig: EndpointConfig,
    public httpHelper: HttpHelper,
    public snackBar: MatSnackBar,
    public uac: UAC,
  ) {
    this.isLabelView = this.isViewLabel() ? 1 : 0
  }

  public layoutMode = WebTaggingComponent.LAYOUT_MODE.normal
  public attributeChanged: boolean = false

  public templateId: string
  public template: TemplateObject
  public datasets: TemplateDataset[]
  public taggingImage: TemplateDataset
  public templateStatus: TemplateStatus


  public projectName: string
  public project: Project
  public taggingColumns: TaggingColumnWithDefinition[]

  public brandImagesPathToIndexMap = {}
  public currentImageIndex: number = -1

  public attachRowIndex: number
  public itemColor: string

  //　ColorPicker制御用
  public colorColumnParam: AgGridBtnComponentParam
  public colorPrevValue: string

  public isLabelView = 0

  public isHideColorSelecter = true
  public isHideBboxAnnotator = false
  public showZoomImage = false
  public isAttach = false

  public isLoadedImage: boolean = false
  public prevEditableStatus4Table = {}
  public errorImageList = {}
  public selectRange = '3×3'

  public objectKeys = Object.keys;
  public rangeList = {
    '1×1': [1, 1],
    '3×3': [3, 3],
    '5×5': [5, 5],
    '7×7': [7, 7],
  }
  public editingUpdateLoop: Subscription = null
  public isCompleteTagging: boolean
  public isInitialLoaded: boolean = false
  public isImageLoading: boolean = false

  public resetScrollProperty: string = null

  gridOptions: GridOptions
  imageLevelGridOptions: GridOptions

  selectedColorEvent = new EventEmitter<string>()
  updateZoomPositionEvent = new EventEmitter<CursorPosition>()
  autoScrollPositoinEvent = new EventEmitter<CursorPosition>()

  autoScrollHandler = CommonUtils.getCursorNotificator(this.autoScrollPositoinEvent)

  public ngOnInit() {
    this.dialog.loadingShow()

    this.templateId = this.route.snapshot.paramMap.get('templateId')

    var setErrorStatus = () => {
      this.errorImageList = {}
      if (!this.templateStatus.importErrors[0] || this.templateStatus.status != 'error') return

      this.templateStatus.importErrors[0].errors.forEach(e => {
        var errorImage = this.datasets[e.path.split('.')[1]]
        errorImage.tmp.isTagging = false

        if (!this.errorImageList[errorImage.src]) {
          this.errorImageList[errorImage.src] = []
        }
        this.errorImageList[errorImage.src].push(Number(e.path.split('.')[3]) + 1 + '行目: ' + e.message)
      })


      if (this.taggingImage && this.errorImageList[this.taggingImage.src]) {
        this.openErrorSnackBar(this.errorImageList[this.taggingImage.src])
      }
    }

    var setProject = project => {
      this.project = project
      this.taggingColumns = this.projectConvertService.toTaggingColumnWithDefinition(project)
      // resetScrollTable()の対象propertyを定義する。
      let _resetScrollColumn = this.taggingColumns.length > 1
        ? this.taggingColumns.filter((taggingColumn) => taggingColumn.taggingLevel == 'item' && taggingColumn.isVisible && taggingColumn.sort == 2)[0]
        : this.taggingColumns.filter((taggingColumn) => taggingColumn.taggingLevel == 'item' && taggingColumn.isVisible)[0]

      this.resetScrollProperty = _resetScrollColumn.isArray
        ? `${_resetScrollColumn.property}.0`
        : _resetScrollColumn.property
    }

    this.taggingApiService.getRunwayTemplateStatus(this.templateId)
      .pipe(
        mergeMap(templateStatus => {
          // projectName, TemplateStatusをセット
          this.templateStatus = templateStatus

          this.projectName = templateStatus.projectName
          return forkJoin(
            this.getProject(this.projectName),
            this.getTemplate(this.templateId)
          )
        }),
        tap(([project, template]) => {
          setProject(project)
          this.template = template
          this.datasets = this.template.datasets.sort((x, y) => {
            return x.src > y.src
              ? 1
              : -1
          })
        }),
        mergeMap(() => {

          if (['created', 'error'].includes(this.templateStatus.status) && this.project.onlinePredictionUrl) {
            return forkJoin(
              this.getNextImage(),
              this.initLoadOnlinePrediction()
            )
          }
          else {
            return forkJoin(
              this.getNextImage(),
              of({ key: '', val: <OnlinePredictionResult>{} })
            )
          }
        }),
        finalize(() => this.dialog.loadingHide())
      )
      .subscribe(([templateDataset, initOnlinePredictionResult]) => {
        // ProjectのselectNode, taggingImageに依存する。
        this.initAttributeTable()
        // initLoadOnlinePrediction()でエラーが発生していない時のみ値をセットするため。
        if (Object.keys(initOnlinePredictionResult.val).length > 0) {
          this.recommendCache[initOnlinePredictionResult.key] = initOnlinePredictionResult.val
        }
        // onlinePredictionのintervalを開始する。
        this.preloadOnlinePrediction()

        this.selectImage(templateDataset)
        //Template Statusに依存する。
        setErrorStatus()

        // タグ付対象画像のカーソル自走スクロール設定
        this.autoScrollPositoinEvent
          .subscribe((p: CursorPosition) => {
            let width = this.taggingImageElementWrapper.nativeElement.clientWidth
            let height = this.taggingImageElementWrapper.nativeElement.clientHeight

            if (p.x > 0.9) {
              this.taggingImageElementWrapper.nativeElement.scrollBy((1.0 - p.x) * width, 0)
            } else if (p.x < 0.1) {
              this.taggingImageElementWrapper.nativeElement.scrollBy(p.x * width * -1, 0)
            }

            if (p.y > 0.9) {
              this.taggingImageElementWrapper.nativeElement.scrollBy(0, (1.0 - p.y) * height)
            } else if (p.y < 0.1) {
              this.taggingImageElementWrapper.nativeElement.scrollBy(0, p.x * height * -1)
            }
          })
      })
  }

  ngOnDestroy(): void {
    this.autoScrollPositoinEvent.unsubscribe()
    if (this.editingUpdateLoop) {
      this.editingUpdateLoop.unsubscribe();
    }

    if (this.recommendSubscriber) {
      this.recommendSubscriber.unsubscribe();
    }
  }

  displayDatasetList = []
  displayOffset = 0
  displaySpan = 20

  onSubclassImagesScroll(e: Event) {
    let target = <HTMLDivElement>e.target
    var position = target.scrollTop;
    var range = target.scrollHeight - target.clientHeight;

    if (position > (range - 30)) {
      this.addDisplayRows()
    }
  }

  addDisplayRows() {
    this.displayDatasetList = this.displayDatasetList.concat(this.datasets.slice(this.displayOffset, this.displayOffset + this.displaySpan))
    this.displayOffset += this.displaySpan
  }

  getProject(projectName: string) {
    var url = this.endpointConfig.riaApi.project.projectName.getUrl(projectName)
    return this.http.get<Project>(url)
  }

  getTemplate(templateId: string): Observable<TemplateObject> {
    var url = this.endpointConfig.riaApi.templates.json.getUrl(templateId)
    return this.http.get<TemplateObject>(url)
  }

  getNextImage(): Observable<TemplateDataset> {

    let user = this.uac.getUser()

    // datasetsを最新の状態に更新
    let nextImage = this.getTemplate(this.templateId)
      .pipe(
        map(template => {

          template.datasets.forEach(dataset => {
            this.addClientPath(dataset)

            dataset.imageTags.forEach(x => {
              let imageDefault = this.createDefaultTag('image')
              Object.keys(imageDefault).forEach(key => {
                if (!(key in x)) {
                  x[key] = imageDefault[key]
                }
              })
            })
            dataset.tags.forEach(x => {
              let itemDefault = this.createDefaultTag('item')
              Object.keys(itemDefault).forEach(key => {
                if (!(key in x)) {
                  x[key] = itemDefault[key]
                }
              })
            })
          })

          this.datasets = template.datasets

          // 初期表示の場合、spanを設定し、NextImageの場合は、現状のOffsetを設定する。
          // ※ImageListの画像表示枚数、スクロール位置を保持するため。
          this.displayOffset = this.displayOffset == 0 ? this.displaySpan : this.displayOffset
          this.displayDatasetList = this.datasets.slice(0, this.displayOffset)

          for (let dataset of this.datasets) {

            if (!dataset.tmp.isTagging) {
              if (!dataset.tmp.editing || dataset.tmp.editing.editor == user.userId) {
                return dataset
              }
            }
          }
          this.isInitialLoaded = true
          return null
        })
      )
    return nextImage
  }

  addClientPath(dataset: TemplateDataset) {
    dataset.tmp.clientImagePath = this.endpointConfig.riaApi.project.dataset.img.getUrl(this.project.projectName, dataset.datasetId, 'src')
    dataset.tmp.clientOrgImagePath = this.endpointConfig.riaApi.project.dataset.img.getUrl(this.project.projectName, dataset.datasetId, 'zoomSrc')

    return dataset
  }

  selectImage(selectImage: TemplateDataset) {
    if (this.taggingImage && selectImage) {
      this.resetAttachAndColor()
      console.log(this.templateStatus.lock)
      //　現在タグ付けをしている画像を選択された場合、resetをスキップする。
      if (this.taggingImage.src == selectImage.src) {
        return
      }
      this.bboxComponent.reset()
    }

    // Imageが読み込み完了までAttributeTableを非活性制御するため
    this.isImageLoading = true

    this.taggingImage = selectImage

    // brandImagesが全体表示されている状態で画像が選択される場合があるため、初期表示と同じ状態にする。
    this.changeModule('bbox')
    this.changeLayoutMode('normal')

    // タグ付が完了した場合等、taggingImageがnullになる場合があるため。
    if (this.taggingImage) {
      this.templateId = selectImage.templateId
      // エラー画像を選択した場合、snack-barを表示する。
      if (this.errorImageList[selectImage.src]) {
        this.openErrorSnackBar(this.errorImageList[selectImage.src])
      } else {
        this.snackBar.dismiss()
      }

      // taggingLevelがimageのリストを作成。
      let taggingLevelImages = this.taggingColumns.filter(x => x.taggingLevel == 'image')

      // ImageLevelのカラム定義が存在し、まだImageTagが存在しない場合、初期値を設定する。
      if (taggingLevelImages.length > 0
        && this.taggingImage.imageTags.length == 0) {
        let _tmp = this.createDefaultTag('image')
        this.taggingImage.imageTags.push(_tmp)

      }

      if (this.gridOptions.api) {
        this.gridOptions.api.setRowData(this.taggingImage.tags)
        this.imageLevelGridOptions.api.setRowData(this.taggingImage.imageTags)
        this.resetScrollTable()
      } else {
        this.gridOptions.rowData = this.taggingImage.tags
        this.imageLevelGridOptions.rowData = this.taggingImage.imageTags
      }
      this.setEditing(this.taggingImage.datasetId)
    }
  }

  handleBboxEvent(eventBbox: BBoxEvent) {
    if (eventBbox.event == 'reset') {
      return
    }

    var bbox = eventBbox.bbox
    var positionList = [bbox['x1'], bbox['y1'], bbox['x2'], bbox['y2']]

    if (eventBbox.event == 'add') {
      if (this.isAttach) {
        this.taggingImage.tags[this.attachRowIndex].bboxId = bbox.id
        this.taggingImage.tags[this.attachRowIndex].bbox = positionList

        //Attach処理を初期状態に戻す。
        this.isAttach = false
        this.attachRowIndex = undefined
        this.uneditable4Table(false)
      } else {
        var itemTag = this.createDefaultTag('item')
        itemTag.bboxId = bbox.id
        itemTag.bbox = positionList
        this.taggingImage.tags.push(itemTag)
      }
      this.resetScrollTable()
      this.setMetadata(bbox.id)
    } else if (eventBbox.event == 'remove') {
      var removeRowIndex = -1
      for (var i = 0; i < this.taggingImage.tags.length; i++) {
        // removeボタンで削除した場合は、removeボタンの処理内でtagsの対象行を削除するため、このif文の処理は実行されない。
        if (this.taggingImage.tags[i].bboxId == bbox.id) {
          removeRowIndex = i
        }
      }
      // 画像内のBboxを直接削除した場合、removeボタンの処理を経由しないため、ここでtags内の対象行を削除する。
      if (removeRowIndex >= 0) {
        this.taggingImage.tags.splice(removeRowIndex, 1)
      }
    } else if (eventBbox.event == 'change') {
      for (let tag of this.taggingImage.tags) {
        if (tag.bboxId == bbox.id) {
          tag.bbox = positionList
        }
      }
    }
    this.gridOptions.api.setRowData(this.taggingImage.tags)
    this.gridOptions.api.redrawRows()
  }


  createDefaultTag(taggingLevel: 'image' | 'item') {

    let _ret = taggingLevel == 'item'
      ? {
        bboxId: '',
        bbox: []
      } : {}

    this.taggingColumns
      .filter(x => x.taggingLevel == taggingLevel)
      .map(x => {
        let propertyName = null
        let defaultValue = null
        if (x.tagDefinition.type == 'flag') {

          defaultValue = x.isArray
            ? Array(x.count).map((x) => false)
            : false

        } else {
          defaultValue = x.isArray
            ? []
            : null
        }

        propertyName = x.isArray
          ? x.property.split('.')[0]
          : propertyName = x.property

        return {
          'key': propertyName,
          'value': defaultValue
        }
      })
      .forEach(x => {
        _ret[x.key] = x.value
      })

    return _ret
  }


  addRow() {
    this.resetAttachAndColor()
    if (this.taggingImage) {
      var itemTag = this.createDefaultTag('item')
      this.taggingImage.tags.push(itemTag)

      this.gridOptions.api.updateRowData({
        add: [itemTag]
      })
    }
    this.resetScrollTable()
  }

  attachBBox(isClick: boolean, param: AgGridBtnComponentParam) {
    this.isAttach = !this.isAttach
    if (isClick) {
      this.attachRowIndex = param.rowIndex
      this.uneditable4Table(true)
    } else {
      this.attachRowIndex = undefined
      this.uneditable4Table(false)
    }
  }

  setInitialBbox(isLoadedImage: boolean) {
    this.isLoadedImage = isLoadedImage
    let leftTagName = this.taggingColumns[0].property

    if (isLoadedImage) {
      this.isInitialLoaded = isLoadedImage // 画面表示の読み込むタイミングのコントロール
      for (let tag of this.taggingImage.tags) {
        if (tag.bbox.length != 0) {
          var bboxPosition = {
            meta: tag[leftTagName],
            x1: tag.bbox[0],
            y1: tag.bbox[1],
            x2: tag.bbox[2],
            y2: tag.bbox[3]
          }

          // 初期表示の行とbbox idの紐づけ
          tag.bboxId = this.bboxComponent.addBBox(bboxPosition, true)
        }
      }
    }
  }


  onLoadedImageEvent(event: boolean) {
    this.setInitialBbox(event)
    this.isImageLoading = !event
  }


  setMetadata(bboxId: string) {
    if (bboxId) {
      let leftTagName = this.taggingColumns[0].property
      var target = this.taggingImage.tags.filter(val => {
        return val.bboxId == bboxId
      })[0]
      this.bboxComponent.setMetadata(target.bboxId, target[leftTagName])
    }
  }

  selectColor(isClick: boolean, params: AgGridBtnComponentParam) {
    if (isClick) {
      this.colorColumnParam = params
      this.colorPrevValue = params.value

      this.changeModule('color')
      this.uneditable4Table(true)
    } else {
      this.resetAttachAndColor()
    }
  }

  handleColorEvent(eventColor) {
    // colorが複数の場合でcolorNumかcolorRowIndexが存在しない場合
    if (!this.colorColumnParam) {
      return
    }

    this.itemColor = eventColor.code

    // 取得したitemColorを設定
    var row = this.colorColumnParam.api.getDisplayedRowAtIndex(this.colorColumnParam.rowIndex)
    row.setDataValue(this.colorColumnParam.column, this.itemColor)

    if (eventColor.isClick) {
      this.changeModule('bbox')
      // colorPicker用の自動入力
      TaggingHelper.addAutoInputTags(this.colorColumnParam, this.project)
      this.gridOptions.api.redrawRows()
      this.uneditable4Table(false)
      this.colorColumnParam = undefined
    }
  }

  // ex. eventColorPicker = #fff
  toColorChangeEvent = ColorHelper.fromColorPicker(false, this.handleColorEvent.bind(this))
  toColorSelectEvent = ColorHelper.fromColorPicker(true, this.handleColorEvent.bind(this))

  changeModule(moduleName: 'bbox' | 'color') {
    if (moduleName == 'bbox') {
      this.isHideColorSelecter = true
      this.isHideBboxAnnotator = false
    } else if (moduleName == 'color') {
      this.isHideColorSelecter = false
      this.isHideBboxAnnotator = true
    }
  }

  remove(isClick: boolean, param: AgGridBtnComponentParam) {
    this.resetAttachAndColor()
    this.taggingImage.tags.splice(param.rowIndex, 1)

    // addで追加された行の削除
    this.gridOptions.api.updateRowData({
      remove: [param.data]
    })

    this.gridOptions.api.redrawRows()

    if (param.data.bboxId) {
      this.bboxComponent.removeBBox(param.data.bboxId)
    }
  }

  changeButton() {
    var id = 'bbox-label-visible-css'
    var style = <HTMLStyleElement>document.getElementById(id)
    if (style) {
      style.disabled = !style.disabled
      this.isLabelView = style.disabled ? 0 : 1
    } else {
      var blankStyle = document.createElement('style')
      blankStyle.type = 'text/css'
      blankStyle.id = id
      var css = `.bbox-caption{
                    display: none;
                    background-color: rgb(127, 255, 127);
                }
        `
      blankStyle.innerHTML = css
      document
        .getElementsByTagName('head')
        .item(0)
        .appendChild(blankStyle)
      this.isLabelView = 1
    }
  }

  isViewLabel(): boolean {
    var id = 'bbox-label-visible-css'
    var style = <HTMLStyleElement>document.getElementById(id)
    return style ? !style.disabled : false
  }

  // ------------------------
  // Layout Change section
  // ------------------------
  changeLayoutMode(layoutName: string) {
    this.layoutMode = WebTaggingComponent.LAYOUT_MODE[layoutName]
  }

  getLayoutMode(): string {
    var layoutNames = Object.keys(WebTaggingComponent.LAYOUT_MODE)
    for (let name of layoutNames) {
      if (this.layoutMode == WebTaggingComponent.LAYOUT_MODE[name]) {
        return name
      }
    }
    throw new Error('Unexpected layout mode.')
  }

  openOriginalImg() {
    this.resetAttachAndColor()
    window.open(this.taggingImage.tmp.clientOrgImagePath)
  }

  doHighlight(bboxId: string) {
    if (this.isLoadedImage) {
      this.bboxComponent.doHighlight(bboxId)
    }
  }

  doNext() {
    this.resetAttachAndColor()

    this.dialog.loadingShow()
    this.taggingImage.tmp.isTagging = true
    var url = this.endpointConfig.riaApi.templates.json.dataset.getUrl(this.templateId, this.taggingImage.datasetId)
    this.logger.debug('Next', this.taggingImage)
    this.http
      .put<TemplateDataset>(url, this.taggingImage)
      .pipe(
        mergeMap(x => {
          return this.getNextImage()
        }),
        finalize(() => this.dialog.loadingHide()))
      .subscribe((nextImage) => {
        if (!nextImage) {
          this.dialog.info('info', WebTaggingComponent.TAGGINGCOMPLETE())
        }
        this.attributeChanged = false
        this.selectImage(nextImage)
      }, this.httpHelper.getDefaultErrorHandler())
  }

  doSubmit() {
    this.resetAttachAndColor()
    this.dialog
      .confirm('Submit', WebTaggingComponent.SUBMIT_CONFIRM(this.templateStatus))
      .pipe(
        filter(x => x),
        tap(() => this.dialog.loadingShow()),
        mergeMap(x => {
          // 不要なプロパティの削除
          this.template.datasets = <TemplateDataset[]>_.cloneDeep(this.datasets)

          let itemTargetKeys = this.taggingColumns
            .filter(x => x.taggingLevel == 'item' && x.isArray)
            .map(x => x.property)

          let imageTargetKeys = this.taggingColumns
            .filter(x => x.taggingLevel == 'image' && x.isArray)
            .map(x => x.property)

          for (let dataset of this.template.datasets) {
            delete dataset['uUser']
            delete dataset['uDate']

            for (let tag of dataset.tags) {
              delete tag.bboxId
              delete tag.author
              delete tag.aiIndex

              itemTargetKeys.forEach(key => {
                tag[key] = tag[key].filter(x => !!x)
              })
            }

            for (let imageTag of dataset.imageTags) {
              imageTargetKeys.forEach(key => {
                imageTag[key] = imageTag[key].filter(x => !!x)
              })
            }
          }
          var reqData = {}
          reqData['batch'] = this.template.datasets
          this.logger.debug('SUBMIT', reqData)

          return this.http.put<DatasetUpdateResponce>(this.endpointConfig.riaApi.project.projectName.datasets.putUrl(this.projectName), reqData)
        }),
        finalize(() => this.dialog.loadingHide())
      )
      .subscribe(
        () => this.router.navigate([`tag/project/${this.projectName}/status-list`]),
        (res: HttpErrorResponse) => {
          if (res.status == 400) {
            var messages = []
            if ('templateStatuses' in res.error && res.error['templateStatuses'].length > 0) {
              res.error['templateStatuses'].forEach((templateStatus: TemplateStatus) => {
                templateStatus.importErrors[0].errors.forEach(e => {
                  messages.push(e.path + ': ' + e.message)
                })
              })
            } else {
              res.error['errors'].forEach(e => {
                messages.push(e.path + ': ' + e.message)
              })
            }
            this.dialog.error('Error', messages.join('<br>')).subscribe(x => {
              this.ngOnInit()
            })
          } else {
            this.httpHelper.getDefaultErrorHandler()(res)
          }
        }
      )
  }


  back() {
    this.resetAttachAndColor()
    this.snackBar.dismiss()
    this.dialog
      .confirm('Back', WebTaggingComponent.BACK_CONFIRM())
      .pipe(filter(x => x))
      .subscribe(rtn => {
        if (this.routerService.getCurrentUrl() === this.routerService.getPreviousUrl()) {
          this.router.navigate(['tag/home'])
        } else {
          this.router.navigateByUrl(this.routerService.getPreviousUrl())
        }
      })
  }

  //AttachまたはColorが押下された状態で他のプロセスを行わないようにするため
  resetAttachAndColor() {
    if (this.colorColumnParam) {

      // tagの値をColor Selectする前の値に戻す。
      var row = this.colorColumnParam.api.getDisplayedRowAtIndex(this.colorColumnParam.rowIndex)
      row.setDataValue(this.colorColumnParam.column, this.colorPrevValue)

      this.colorColumnParam = undefined
      this.colorPrevValue = null
    }

    if (this.attachRowIndex >= 0) {
      this.attachRowIndex = undefined
    }
    this.changeModule('bbox')
    // タグ付完了後、attribute tableを表示しない場合があるため
    if (this.gridOptions.columnApi && this.gridOptions.api) {
      this.uneditable4Table(false)
      this.gridOptions.api.redrawRows()
    }
  }

  resetScrollTable() {
    this.gridOptions.api.ensureColumnVisible(this.resetScrollProperty)
  }

  uneditable4Table(isDisabled: boolean) {
    if (isDisabled) {
      for (let column of this.gridOptions.columnApi.getAllColumns()) {
        // Cansel処理,Attach処理,Color Select処理の際にeditableを元の状態に戻すため、保持しておく。
        this.prevEditableStatus4Table[column.getColId()] = column.getColDef().editable
        column.getColDef().editable = false
      }
    } else {
      //元のeditableの値に戻す

      for (let column of this.gridOptions.columnApi.getAllColumns()) {
        if (column.getColId() in this.prevEditableStatus4Table) {
          column.getColDef().editable = this.prevEditableStatus4Table[column.getColId()]
        }
      }
    }
  }

  // ------------------------
  // Attribute Table section
  // ------------------------
  initAttributeTable() {
    if (this.gridOptions) {
      return
    }

    var columnDefs = this.projectConvertService.toColumnDefs(this)

    this.gridOptions = {
      context: {
        componentParent: this
      },
      rowHeight: 40,
      rowModelType: 'clientSide',
      rowDeselection: true,
      suppressRowClickSelection: true,
      suppressMovableColumns: true,
      onGridReady: this.onGridReady.bind(this),
      onCellValueChanged: (event: CellValueChangedEvent) => {
        this.attributeChanged = true
        let bboxId = this.taggingImage.tags[event.rowIndex].bboxId
        if (bboxId) {
          this.setMetadata(bboxId)
        }
      },
      onCellMouseOver: (event: CellMouseOverEvent) => {
        let bboxId = this.taggingImage.tags[event.rowIndex].bboxId
        if (bboxId) {
          this.doHighlight(bboxId)
        }
      },
      onCellMouseOut: (event: CellMouseOutEvent) => {
        this.doHighlight('')
      }
    }

    this.gridOptions.columnDefs = columnDefs['tags']

    this.imageLevelGridOptions = {
      context: {
        componentParent: this
      },
      rowHeight: 40,
      rowModelType: 'clientSide',
      rowDeselection: true,
      suppressRowClickSelection: true,
      suppressMovableColumns: true,
      onGridReady: this.onGridReady.bind(this),
      onCellValueChanged: (event: CellValueChangedEvent) => {
        this.attributeChanged = true
      }
    }
    this.imageLevelGridOptions.columnDefs = columnDefs['imageTags']
  }

  // ------------------------
  // ag-grid callback section
  // ------------------------
  onGridReady() { }

  uneditable4Color(params: AgGridBtnComponentParam, attachRowIndex, colorColumnParam: AgGridBtnComponentParam) {
    if (attachRowIndex >= 0) {
      return true
    }
    // color mode and !( 選択行　and　indexが正しい)
    return colorColumnParam
      && !(colorColumnParam.rowIndex == params.rowIndex
        && colorColumnParam.column == params.column)
  }


  onCellValueChanged(params: IsColumnFuncParams) {
    if (params.data.bboxId) {
      this.setMetadata(params.data.bboxId)
    }
    TaggingHelper.deleteCellValue4DisabledPropertiesTarget(params, this.project)
    TaggingHelper.deleteCellValue4HiddenTagsTarget(params, this.project)
    TaggingHelper.addAutoInputTags(params, this.project)

    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex)
    this.gridOptions.api.redrawRows({
      rowNodes: [rowNode]
    })
    this.gridOptions.api.setFocusedCell(params.node.rowIndex, params.column)
  }

  openErrorSnackBar(messages: string[]) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: messages,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      panelClass: ['web-tagging-page', 'error-snackbar']
    })
  }

  updateZoomPosition(eventPosition: CursorPosition) {
    this.updateZoomPositionEvent.emit(eventPosition)
  }

  // ------------------------
  // editing section
  // ------------------------
  @taggingStatusGuard
  setEditing(datasetId) {
    this.http.post(this.endpointConfig.riaApi.templates.json.editing.getUrl(this.templateId, datasetId), '')
      .subscribe(
        () => this.startUpdateEditingInterval(datasetId),
        this.getEditingErrorHandler(409)
      )
  }

  @taggingStatusGuard
  startUpdateEditingInterval(datasetId: string) {
    if (this.editingUpdateLoop) {
      this.editingUpdateLoop.unsubscribe()
    }

    this.editingUpdateLoop = interval(30 * 1000)
      .pipe(
        mergeMap(v => this.http.patch(this.endpointConfig.riaApi.templates.json.editing.getUrl(this.templateId, datasetId), ''))
      )
      .subscribe(
        noop,
        this.getEditingErrorHandler(404)
      )
  }

  getEditingErrorHandler(statusCode: number): (res: HttpErrorResponse) => void {
    return (res: HttpErrorResponse) => {
      if (res.status == statusCode) {
        var messages = []
        messages.push(res.error.message)
        this.dialog.error('Error', messages.join('<br>'))
          .pipe(
            mergeMap(v => this.getNextImage())
          )
          .subscribe(image => {
            this.selectImage(image)
          })
      } else {
        this.httpHelper.getDefaultErrorHandler()(res)
      }
    }
  }

  // ------------------------
  // recommend section
  // ------------------------
  private recommendSubscriber: Subscription;
  private recommendCache: { [key: string]: OnlinePredictionResult } = {};

  @taggingStatusGuard
  @onlinePredictionGuard
  initLoadOnlinePrediction() {

    let _noTagDatasets = this.datasets.filter((dataset) => !dataset.tags.length)
    // タグ付対象画像が1枚もない場合、初回predict結果を空オブジェクトで返す。
    if (_noTagDatasets.length == 0) {
      return of(
        {
          key: '',
          val: <OnlinePredictionResult>{}
        }
      )
    }
    // タグ付対象画像が1枚以上ある場合、predictを実施する。
    return of(_noTagDatasets[0].src)
      .pipe(
        mergeMap((_src) => {
          return this.http.post(this.project.onlinePredictionUrl, {
            projectName: this.projectName,
            path: _src
          })
            .pipe(
              map(recomemend => {
                return {
                  key: _src,
                  val: <OnlinePredictionResult>recomemend
                }
              }),
              catchError((error: HttpErrorResponse) => {
                this.logger.info('Init Prediction Error', error.message)
                return of(
                  {
                    key: _src,
                    val: <OnlinePredictionResult>{}
                  }
                )
              })
            )
        })
      )
  }

  @taggingStatusGuard
  @onlinePredictionGuard
  preloadOnlinePrediction() {
    if (this.recommendSubscriber) {
      this.recommendSubscriber.unsubscribe()
    }

    this.recommendSubscriber = zip(
      interval(10 * 1000),
      from(
        // initLoadOnlinePrediction()で最初のdatasetをpredict済みのため、2個目のdataset以降に対してpredictを実施する。
        this.datasets.slice(1)
          .filter((image) => !image.tags.length)
          .map(image => image.src))
    ).pipe(
      mergeMap((zipper) => {
        let src = zipper[1]
        return this.http.post(this.project.onlinePredictionUrl, {
          projectName: this.projectName,
          path: src
        })
          .pipe(
            map(recomemend => {
              return {
                key: src,
                val: <OnlinePredictionResult>recomemend
              }
            })
          )
      })
    ).subscribe(ret => {
      this.recommendCache[ret.key] = ret.val
    })
  }

  @onlinePredictionGuard
  doOnlinePrediction() {

    this.dialog.loadingShow()
    let _observable: Observable<OnlinePredictionResult> = this.recommendCache[this.taggingImage.src]
      ? of(this.recommendCache[this.taggingImage.src])
      : this.http.post<OnlinePredictionResult>(this.project.onlinePredictionUrl, {
        projectName: this.projectName,
        path: this.taggingImage.src
      })

    _observable.pipe(
      map(recomemend => {
        return {
          key: this.taggingImage.src,
          val: <OnlinePredictionResult>recomemend
        }
      }),
      mergeMap(ret => {
        let _predictTags = ret.val['tags']
        let _tags = []
        for (let i = 0; i < _predictTags.length; i++) {
          let _predictTag = _predictTags[i]
          let _tag = this.createDefaultTag('item')
          for (let key in _tag) {
            if (_predictTag[key] && (_tag[key] == null || (typeof _predictTag[key] == typeof _tag[key]))) {
              _tag[key] = _predictTag[key]
            }
          }
          _tag['aiIndex'] = i
          _tag['author'] = "AI"
          _tags.push(_tag)
        }

        this.taggingImage.tags = <any>_tags
        this.gridOptions.api.setRowData(this.taggingImage.tags)

        return this.http.post(this.endpointConfig.riaApi.templates.json.recommend.getUrl(
          this.templateId,
          this.taggingImage.datasetId),
          ret.val)
      }),
      finalize(() => this.dialog.loadingHide())
    ).subscribe(
      (val) => {
        var _val = <any>val
        if (_val.recommendTags.tags.length == 0) {
          this.dialog.warning('Warning', '申し訳ございません。予測出来ませんでした。')
        }

        this.setInitialBbox(true)
      },
      (res: HttpErrorResponse) => {
        if (res.status == 404) {
          var messages = []
          messages.push(res.error.message)
          this.dialog.error('Error', messages.join('<br>'))
        } else {
          this.httpHelper.getDefaultErrorHandler()(res)
        }
      })
  }

  cpToggleChangeEvent($event: boolean) {
    if (!$event) {
      this.resetAttachAndColor()
    }
  }

  nonAnonymizationAlert(selectedDataset?: TemplateDataset){
    const reqBody = selectedDataset ? {
      "datasetIds":[selectedDataset.datasetId],
      "omitAlert": false
    } : {
      "datasetIds":this.datasets.map(dataset => dataset.datasetId),
      "omitAlert": true
    }

    this.dialog.loadingShow()
    this.http.post(
      this.endpointConfig.riaApi.alert.nonAnonymized.postUrl(this.projectName),
      reqBody
      ).pipe(
        finalize(() => this.dialog.loadingHide())
      ).subscribe(
        (res:NonAnonymizedAlertResult) => {
          this.dialog.info(
            'Alert non anonymized datasets',
            WebTaggingComponent.ALERT_NON_ANONYMIZED_DATASETS(res.alertedCount, res.failedDatasetIds))        },
        (res: HttpErrorResponse) => {
          if (res.status == 400) {
            const messages: Array<string> = res.error.errors.map(e => e.message)
            this.dialog.error('Error', messages.join('<br>')).subscribe(
              () => console.log('error confirmed')
            )
          } else {
            this.httpHelper.getDefaultErrorHandler()(res)
          }
        }
      )
  }

  deleteImageRequest(selectedDataset: TemplateDataset) {
    const reqBody = {
      "datasetIds": [selectedDataset.datasetId],
    }

    this.dialog.loadingShow()

    this.http.post(
      this.endpointConfig.riaApi.request.deleteDataset.postUrl(this.projectName),
      reqBody
    ).pipe(
      finalize(() => this.dialog.loadingHide())
    ).subscribe(
      (res: RequestDeleteDatasetResult) => {
        this.dialog.info(
          'Request delete dataset',
          WebTaggingComponent.REQUEST_DELETE_DATASET(res.requestCount, res.requestFailedDataset))
      },
      (res: HttpErrorResponse) => {
        if (res.status == 400) {
          const messages: Array<string> = res.error.errors.map(e => e.message)
          this.dialog.error('Error', messages.join('<br>')).subscribe(
            () => console.log('error confirmed')
          )
        } else {
          this.httpHelper.getDefaultErrorHandler()(res)
        }
      }
    )
  }
}
