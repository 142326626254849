import { Router, NavigationEnd } from "@angular/router";
import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";
import { NGXLogger } from 'ngx-logger';

@Injectable({
    providedIn: 'root'
})
export class RouterService {

    private previousUrl: string = undefined;
    private currentUrl: string = undefined;
    private previousUrlExcludeQueryParam: string  = '';
    private currentUrlExcludeQueryParam: string  = '';

    constructor(
        private router: Router,
        private logger: NGXLogger) {

        this.currentUrl = this.router.url;
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;

                let previousUrlExcludeQueryParam = this._trimQueryParam(this.previousUrl)
                let currentUrlExcludeQueryParam = this._trimQueryParam(this.currentUrl)
                if (previousUrlExcludeQueryParam !== currentUrlExcludeQueryParam) {
                  this.previousUrlExcludeQueryParam = this._trimQueryParam(this.previousUrl)
                  this.currentUrlExcludeQueryParam = this._trimQueryParam(this.currentUrl)
                }

                this.logger.debug('RouterService: ', this.previousUrl, this.currentUrl);
                this.logger.debug('RouterService Exclude Query Param: ', this.previousUrlExcludeQueryParam, this.currentUrlExcludeQueryParam);
            };
        });

    }

    public getCurrentUrl(excludeQueryParam: boolean=false) {
        return excludeQueryParam ? this.currentUrlExcludeQueryParam : this.currentUrl
    }

    public getPreviousUrl(excludeQueryParam: boolean=false) {
        return excludeQueryParam ? this.previousUrlExcludeQueryParam : this.previousUrl;
    }

    private _trimQueryParam(url: string) {
      let _index = url.indexOf('?')
      return _index > -1 ? url.slice(0, _index) : url
    }
}
