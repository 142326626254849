import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { DefinitionService } from 'src/app/service/server/definition-service';
import { mergeMap, tap, first, last, map, finalize, take, filter } from 'rxjs/operators';
import { CommonUtils, SearchPageUtils } from 'src/app/utils/common';
// import { RunwayTemplate } from 'src/app/model/runway/runwayTemplate';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, noop, of } from 'rxjs';
import { TaggingApiService } from 'src/app/service/server/tag-service';
import { DialogService } from 'src/app/service/dialog-service';
import { HttpHelper } from 'src/app/utils/http-helper';
import { EndpointConfig } from 'src/app/config/endpoint-config';
import { Project, CategoryColumn } from 'src/app/model/project/project';
import { TemplateStatusList, TemplateStatus, ProgressDataTemplateStatus } from 'src/app/model/template/templateStatus';
// import { RunwayTemplateStatusList } from 'src/app/model/runway/runwayTemplateStatus';
import { RouterService } from '../../service/router-service';
import { Dictionary } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import * as _ from 'lodash';
import { TaggingLockerComponent } from '../lock/lock.component';
import { UAC } from 'src/app/service/user/uac';
import { TemplateGroup } from 'src/app/model/template/template';

interface SearchForm {
  category: string;
  subclass: string[];
  status: string[];
  fromDate: Date;
  toDate: Date;
}

@Component({
  selector: 'tag-status-list',
  templateUrl: './status-list.component.html',
  styleUrls: ['./status-list.component.css']
})
export class TaggingStatusListComponent implements OnInit {

  static WEB_TAGGING_CONFIRM_MSG = (loginId, exportUserId) => {
    return `
    このLockは <strong>${exportUserId}</strong>が作成したデータです。
    本当にこのデータのタグ付を実施しますか？
    `
  }


  public projectName: string = ''
  private taggingPreset: string[]
  private templateList: TemplateStatusList
  private progressData: ProgressDataTemplateStatus
  private progressDataList: ProgressDataTemplateStatus[] = []
  private progressDataCount: number
  private categoryArray: CategoryColumn[] = []
  private subclassList: string[] = []
  private notExistList: string[] = []
  private progresCategory: string
  private taggingPresetMode: boolean = false
  private templateGroupMode: boolean = false
  private importedSubclassCount: number = 0
  private withoutNotExistsCount: number = 0
  private templateGroups: TemplateGroup[] = []
  private templateGroupCount: number = 0

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: DialogService,
    private definitionService: DefinitionService,
    private taggingApiService: TaggingApiService,
    private routerService: RouterService,
    private httpHelper: HttpHelper,
    private uac: UAC,
    private endpointConfig: EndpointConfig
  ) {
  }

  totalCount = 0

  templatesList: TemplateStatus[];

  private sep: string = ' ';
  private searchForm: SearchForm = {
    category: '',
    subclass: [],
    status: [],
    fromDate: null,
    toDate: null
  };
  filterForm = {
    cUser: '',
    iUser: '',
    description: '',
    status: ''
  }

  private searchFormDefinition = {
    'category': {
      'type': String,
      'predicate': (x) => !CommonUtils.isEmpty(x)
    },
    'subclass': {
      'type': Array,
      'predicate': (x) => !CommonUtils.isEmpty(x)
    },
    'status': {
      'type': Array,
      'predicate': (x) => !CommonUtils.isEmpty(x)
    },
    'toDate': {
      'type': Date
    },
    'fromDate': {
      'type': Date
    }
  }

  project: Project = null;
  categoryList: string[] = [''];
  categoryToSubclassMap: { [key: string]: string[] } = {};

  statusList: string[] = this.definitionService.templateStatus

  private toProgresCheckObject(x: TemplateStatus) {
    return {
      category: x.lock.category,
      subclass: x.lock.subclasses[0],
      status: x.status,
      cDate: x.cDate,
      iDate: x.iDate,
      cUser: x.cUser,
      iUser: x.iUser,
      format: x.format,
      templateId: x.templateId,
      canDownload: (x.status === 'created' || x.status === 'error'),
      canCancel: (x.status === 'created' || x.status === 'error'),
      canExport: (x.status === 'untouched' || x.status === 'canceled')
    }
  }

  public ngOnInit() {
    this.dialog.loadingShow()

    this.projectName = this.route.snapshot.paramMap.get('projectName')
    let queryParam = this.route.snapshot.queryParams
    // クエリパラメータにdescriptionがある場合はフォームにセットする。
    this.filterForm.description = queryParam.description
      ? queryParam.description
      : ''
    let conditions = SearchPageUtils.queryParamToConditions(this.searchFormDefinition, queryParam);
    SearchPageUtils.setSearchForm(this.searchFormDefinition, conditions, this.searchForm)


    this.getProject(this.projectName).pipe(
      tap(project => {
        this.project = project
        this.project.datasetInfo.category.forEach((val) => {
          this.categoryList.push(val.category)
          this.categoryToSubclassMap[val.category] = val.subclass
        })
        this.taggingPreset = project.taggingPreset
        this.categoryArray = project.datasetInfo.category
        if (project.taggingGroup == 'subclass') {
          this.taggingPresetMode = true
        } else if (project.taggingGroup == 'none') {
          this.templateGroupMode = true
        }
      }),
      mergeMap(() => {
        return this.project.taggingGroup == 'none'
          ? this.taggingApiService.getTemplateGroupList(this.projectName)
            .pipe(
              tap(x => {
                this.templateGroups = x.templateGroups
                this.templateGroupCount = x.totalCount
              })
            )
          : of([])
      }),
      mergeMap(_ => {
        return this._search(conditions)
      }),
      finalize(() => this.dialog.loadingHide())
    ).subscribe(noop)
  }

  getProject(projectName: string) {
    var url = this.endpointConfig.riaApi.project.projectName.getUrl(projectName)
    let options = { params: new HttpParams().set('includeDatasetInfo', 'true') };
    return this.http.get<Project>(url, options)
  }

  search(): void {
    this.dialog.loadingShow()
    var conditions = SearchPageUtils.searchFormToConditions(this.searchFormDefinition, this.searchForm);
    this.router.navigate(this.route.snapshot.url.map(x => x.path), {
      queryParams: conditions
    })

    this._search(conditions)
      .pipe(
        finalize(() => this.dialog.loadingHide())
      ).subscribe(noop, this.httpHelper.getDefaultErrorHandler())
  }

  private _search(query): Observable<TemplateStatusList> {
    return this.taggingApiService.getRunwayTemplateStatusList(this.project.projectName, query).pipe(
      tap((x) => {
        this.templatesList = x.statuses.sort((a, b) => {
          return a.cDate == b.cDate
            ? 0
            : a.cDate >= b.cDate ? -1 : 1
        })
        this.totalCount = x.totalCount

        if (this.project.taggingGroup != 'subclass' || this.totalCount == 0 && !this.searchForm.category) {
          this.progressDataList = []
          this.progressDataCount = 0
          this.importedSubclassCount = 0
          this.withoutNotExistsCount = 0
          return
        }

        let progresCategory = this.totalCount == 0
          ? this.searchForm.category
          : this.templatesList[0].lock.category
        this.progresCategory = progresCategory
        let exsitsSubclasses = this.categoryToSubclassMap[progresCategory]

        let subclassToTemplate = {}
        this.templatesList
          .filter(x => x.lock.category == progresCategory)
          .forEach(x => {
            for (let subclass of x.lock.subclasses) {
              if (!subclassToTemplate[subclass]) {
                let _clone = _.cloneDeep(x)
                _clone.lock.subclasses = [subclass]
                subclassToTemplate[subclass] = _clone
              }
            }
          })


        let progresCheckList = []
        for (let preset of this.taggingPreset) {
          if (subclassToTemplate[preset]) {
            progresCheckList.push(this.toProgresCheckObject(subclassToTemplate[preset]))
          } else {
            progresCheckList.push({
              category: this.progresCategory,
              subclass: preset,
              status: exsitsSubclasses.indexOf(preset) == -1 ? 'not_exists' : 'untouched',
              cDate: null,
              iDate: null,
              cUser: '',
              iUser: '',
              format: '',
              templateId: '',
              canDownload: null,
              canCancel: null,
              canExport: null
            })
          }
        }
        this.progressDataCount = Object.keys(subclassToTemplate).length
        this.progressDataList = progresCheckList

        // statusがimportedになっているSubclassを画面に表示するため
        this.importedSubclassCount = this.progressDataList.filter((subclassStatus) => {
          return subclassStatus.status == 'imported'
        }).length
        // 該当projectに存在するsubclassを画面に表示するため
        this.withoutNotExistsCount = this.progressDataList.filter((subclassStatus) => {
          return subclassStatus.status != 'not_exists'
        }).length

      })
    )
  }

  // ダイアログ画面への処理
  displaySubclassSelection(selectedCategory: string) {

    let subclassList = this.categoryToSubclassMap[selectedCategory].sort()

    this.dialog.subclassSelection({
      selectedSubclass: subclassList.map((subclass) => {
        return {
          name: subclass,
          isSelected: this.searchForm.subclass.indexOf(subclass) >= 0
        }
      }),
      taggingPreset: this.project.taggingPreset
    }).subscribe(
      (selectedSubclasses: string[]) => {
        if (selectedSubclasses == undefined) {
          selectedSubclasses = []
        }
        this.searchForm.subclass = selectedSubclasses
      })
  }

  resetBrands() {
    this.searchForm.subclass = [];
  }

  resetFilter() {
    this.filterForm = {
      cUser: '',
      iUser: '',
      description: '',
      status: ''
    }
  }


  cancel(templateId: string) {
    var msg = '対象のLockをキャンセルします。よろしいですか？'
    this.dialog.confirm('Confirm', msg).pipe(
      filter(x => x),
      tap(() => this.dialog.loadingShow()),
      mergeMap((ok) => {
        let url = this.endpointConfig.riaApi.project.projectName.templateId.getUrl(this.project.projectName, templateId)
        return this.http.delete<TemplateStatus>(url)
      }),
      mergeMap((x) => {
        var conditions = SearchPageUtils.searchFormToConditions(this.searchFormDefinition, this.searchForm);
        return this._search(conditions);
      }),
      finalize(() => this.dialog.loadingHide()),
    ).subscribe(
      noop,
      this.httpHelper.getDefaultErrorHandler()
    )
  }


  download(templateId: string) {
    this.dialog.confirm('Information', 'Download starts.').subscribe(
      (ok) => {
        if (ok) {

          window.open(this.endpointConfig.riaApi.tags.templates.file.getUrl(templateId, 'excel'), '_blank')
        }
      }
    );
  }

  lock(subclass: string) {
    this.dialog.confirm('Lock', TaggingLockerComponent.TEMPLATE_CONFIRM(this.project.taggingGroup, this.progresCategory, subclass))
      .pipe(
        filter(x => x),
        tap(() => this.dialog.loadingShow()),
        mergeMap(x => {

          let reqData = {}
          if (this.project.taggingGroup == 'category') {

            reqData['category'] = this.progresCategory
            reqData['lockLevel'] = "category"
            reqData['projectName'] = this.project.projectName

          } else if (this.project.taggingGroup == 'subclass') {

            reqData['category'] = this.progresCategory
            reqData['lockLevel'] = "subclass"
            reqData['projectName'] = this.project.projectName
            reqData['subclasses'] = [subclass]

          }
          return this.http.post(this.endpointConfig.riaApi.templates.json.postUrl(), reqData)
        }),
        finalize(() => this.dialog.loadingHide())
      )
      .subscribe(
        (templateStatus) => {
          let templateId = templateStatus['templates'][0]['templateId']
          this.router.navigate(['tag/web-tagging/' + templateId])
        },
        (res: HttpErrorResponse) => {
          if (res.status == 400 || res.status == 409) {
            var messages = []
            res.error.errors.forEach(e => {
              messages.push(e.message)
            })
            this.dialog.error('Error', messages.join('<br>')).subscribe(x => {
              this.ngOnInit()
            })
          } else {
            this.httpHelper.getDefaultErrorHandler()(res)
          }
        }
      )
  }
  toWebTagging(template: TemplateStatus) {

    if (template.cUser == this.uac.getUser().userId) {
      this.router.navigate(['/tag/web-tagging', template.templateId])
    } else {
      this.dialog.confirm('Warning',
        TaggingStatusListComponent.WEB_TAGGING_CONFIRM_MSG(this.uac.getUser().userId, template.cUser))
        .pipe(
          filter(x => x),
        ).subscribe(_ => {
          this.router.navigate(['/tag/web-tagging', template.templateId])
        })
    }
  }

  toTemplateGroupWebTagging(templateGroup: TemplateGroup) {

    if (templateGroup.cUser == this.uac.getUser().userId) {
      this.router.navigate(['/tag/web-tagging/template-group', templateGroup.templateGroupId])
    } else {
      this.dialog.confirm('Warning',
        TaggingStatusListComponent.WEB_TAGGING_CONFIRM_MSG(this.uac.getUser().userId, templateGroup.cUser))
        .pipe(
          filter(x => x),
        ).subscribe(_ => {
          this.router.navigate(['/tag/web-tagging/template-group', templateGroup.templateGroupId])
        })
    }
  }
}
