import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material";

export interface TemplateGroupData {
    ok: boolean;
    description: string;
    splitSize: number;
}


@Component({
    selector: 'app-template-group-data-input',
    templateUrl: './template-group-data-input.component.html',
})
export class TemplateGroupDataInputComponent {

    description: string = null
    splitSize: number = 200
    splitSizeList: number[] = [100, 200, 300, 400, 500]

    constructor(
        public dialogRef: MatDialogRef<TemplateGroupDataInputComponent, TemplateGroupData>
    ) { }

    ok() {
        this.dialogRef.close({
            ok: true,
            description: this.description,
            splitSize: this.splitSize
        })
    }

    cancel() {
        this.dialogRef.close({
            ok: false,
            description: null,
            splitSize: null
        })
    }


}