import { CollectionPrefixes } from 'src/app/model/collection/collectionPrefix';
import { RunwayTagBrandList, RunwayTagBrand } from 'src/app/model/runway/runwayTagBrand';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, defer, of } from 'rxjs';
import { TagDefinition } from 'src/app/model/runway/tag';
import { EndpointConfig } from 'src/app/config/endpoint-config';
import { map } from 'rxjs/operators';


@Injectable()
export class DefinitionService {

    public templateStatus = [
        "",
        "request",
        "creating",
        "created",
        "importing",
        "imported",
        "canceled",
        "error"
    ]

    constructor(
        private http: HttpClient,
        private endpointConfig: EndpointConfig
    ) {
    }

    public getTagDefinitions(author: string): Observable<TagDefinition> {
        return this.http.get<TagDefinition>(this.endpointConfig.riaApi.tags.definitions.getUrl(author), {
            headers: new HttpHeaders({
                'x-client-cachable': 'true'
            })
        })
    }

    public getCollectionPrefixByCollection(collection: string): Observable<string> {

        var getPrefix = (collectionPrefixes: CollectionPrefixes, _collection: string): string => {
            var _prefix = Object.keys(collectionPrefixes).filter((prefix) => {
                var _collections = collectionPrefixes[prefix];
                return Object.prototype.toString.call(_collections) === '[object Array]' && _collections.indexOf(_collection) >= 0;
            })[0];
            return _prefix
        }

        return this.http.get<CollectionPrefixes>(this.endpointConfig.riaApi.collections.prefixes.getUrl(), {
            headers: new HttpHeaders({
                'x-client-cachable': 'true'
            })
        }).pipe(
            map(x => getPrefix(x, collection))
        )
    }

    public getTaggingBrandListByPrefix(collectionPrefix: string): Observable<string[]> {

        var getBlandListByPrefix = (taggingBrandList: RunwayTagBrand[], prefix: string): string[] => {
            var brandList = taggingBrandList.filter((val) => {
                return val.collection === prefix;
            })[0].brands
            return brandList;
        }

        return this.http.get<RunwayTagBrandList>(this.endpointConfig.riaApi.tags.brands.getUrl('uq', 'all'), {
            headers: new HttpHeaders({
                'x-client-cachable': 'true'
            })
        }).pipe(
            map(x => getBlandListByPrefix(x.brands, collectionPrefix))
        )
    }


    public getPrexixAndFilteredCollections(collections: string[]): Observable<{ [key: string]: string[]; }> {
        // collectionPrefixesについて「引数に指定したcollectionのリストに存在する」というフィルタをかけて
        // prefixをkey, collectionのリストをvalueにもつObjectを返すpromiseを取得するメソッド

        var getFilterdCollections = (prefixes: CollectionPrefixes): { [key: string]: string[]; } => {
            var filteredCollections = {};
            for (var prefix in prefixes) {
                var _collections = prefixes[prefix];
                if (_collections instanceof Array && _collections) {
                    // 該当prefixの全collectionを、collectionsに含まれているかどうかでフィルタリング
                    var filteredCollectionList = _collections.filter((collection) => {
                        return collections.indexOf(collection) >= 0;
                    });
                    // フィルタリング後のcollectionリストが空のprefixは除外する。
                    if (filteredCollectionList.length > 0) {
                        filteredCollections[prefix] = filteredCollectionList;
                    };
                };
            }
            return filteredCollections
        }

        return this.http.get<CollectionPrefixes>(this.endpointConfig.riaApi.collections.prefixes.getUrl(), {
            headers: new HttpHeaders({
                'x-client-cachable': 'true'
            })
        }).pipe(
            map(x => {
                return getFilterdCollections(x)
            })
        )

    }
}
