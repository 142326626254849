import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { zip, interval, from, of, Observable, Subscription, noop } from 'rxjs';
import { mergeMap, map, catchError, filter, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { AuthorizationTokenInterceptor } from 'src/app/service/user/uac';
import { DialogService } from 'src/app/service/dialog-service';

interface ConfirmedKeyword {
  keyword: string;
  retCode: string;
  postCount: string;
  url: string;
  status: 'initial' | 'loading' | 'complete' | 'error';
}

@Component({
  selector: 'app-instagram-post-checker',
  templateUrl: './instagram-post-checker.component.html',
  styleUrls: ['./instagram-post-checker.component.css']
})
export class InstagramPostCheckerComponent implements OnInit, OnDestroy {


  confirmedKeywordList: ConfirmedKeyword[] = []
  searchKeywords: string = ''

  filterForm = {
  }

  counter = {
    complete: 0,
    error: 0,
    initial: 0,
    loading: 0
  }

  private waitSecond = 2;
  private unsubscribers: Subscription[] = []

  constructor(
    private httpClient: HttpClient,
    private logger: NGXLogger,
    private dialogService: DialogService
  ) { }

  ngOnInit(
  ) {
    this.checkProgress()
  }

  ngOnDestroy(): void {
    this.unsubscribers.forEach(x => x.unsubscribe())
  }

  checkProgress() {
    let subscription = interval(10 * 1000)
      .pipe(
        filter(x => this.confirmedKeywordList.length > 0),
        map(x => {
          Object.keys(this.counter).forEach(key => this.counter[key] = 0)
          this.confirmedKeywordList.forEach(x => this.counter[x.status] += 1)

          return (this.counter.complete + this.counter.error) == this.confirmedKeywordList.length
        }),
        filter(x => x)
      )
      .subscribe(x => {
        subscription.unsubscribe()
        this.dialogService.info('Info', 'Instagram post check has been finished.')
      })

    this.unsubscribers.push(subscription)
  }

  checkStart() {
    this.confirmedKeywordList = this.searchKeywords.split(/(\r\n|\n)/)
      .map(x => x.trim())
      .filter(x => x.length > 0)
      .map(x => {
        return <ConfirmedKeyword>{
          keyword: x,
          url: `https://www.instagram.com/explore/tags/${encodeURIComponent(x)}/?hl=ja`,
          retCode: "",
          postCount: '',
          status: 'initial'
        }
      })

    zip(
      interval(this.waitSecond * 1000),
      from(this.confirmedKeywordList)
    ).pipe(
      mergeMap((zipper): Observable<ConfirmedKeyword> => {
        let keyword = zipper[1]
        keyword.status = 'loading'

        let httpHeaders = new HttpHeaders()
          .append(AuthorizationTokenInterceptor.SKIP_AUTHORIZATION_HEADER_KEY, "true")
          .append('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8');

        return this.httpClient.get(keyword.url, {
          headers: httpHeaders,
          responseType: 'text'
        }).pipe(
          map(html => {

            let parser = new DOMParser()
            let document = parser.parseFromString(html, 'text/html')
            let content = document.querySelectorAll('meta[name=description]').item(0).getAttribute('content')
            let postCount = content.match(/^(投稿.*件).*/)[1]

            keyword.retCode = "200"
            keyword.postCount = postCount
            keyword.status = 'complete'

            return keyword
          }),
          catchError((error: HttpErrorResponse) => {

            keyword.retCode = '' + error.status
            keyword.status = 'error'

            return of(keyword)
          })
        )
      })
    ).subscribe(() => {})

  }


  openHelpDiaglog() {


    this.dialogService.info('How to use this page.', `
    本画面は、特定キーワードに対してInstagramでの投稿件数を確認するための画面です。<br>
    画面上部のテキストエリアにて、キーワードを複数列挙することが出来ます。（1行1キーワードとなる。）<br>
    キーワード入力後に、「Check Start」ボタンを押下する事で、列挙したキーワードの件数をInstagarmから取得します。<br>
    <strong>
      現在はキーワード間のWaitを${this.waitSecond}秒に設定されています。<br>
      つまりキーワード数 * ${this.waitSecond}秒が最低限 完了までかかります。
    </strong>
    <hr>
    <p>
      ret codeの意味<br>
        200： Instagramに該当キーワードのPageが存在する場合<br>
        404： Instagramに該当キーワードのPageが存在しない場合<br>
    </p>
    `).subscribe(x => {})
  }

}
