import { EventEmitter, Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialog, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { SubclassSelectionComponent, SubclassSelectionModel } from 'src/app/component/subclass-selection/subclass-selection.component';
import { DialogComponent } from 'src/app/component/dialog/dialog.component';
import { FormatSelectionComponent, FormatSelectionResult } from '../component/format-selection/format-selection.component';
import { TemplateGroupDataInputComponent, TemplateGroupData } from '../component/template-group-data-input/template-group-data-input.component';
import { TaggingGroupSelectionComponent, TaggingGroupSelectionModel, TaggingGroupSelectionData } from 'src/app/component/tagging-group-selection/tagging-group-selection.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    public overlayEvent$ = new EventEmitter<boolean>()

    constructor(
        @Inject(MAT_DIALOG_DEFAULT_OPTIONS) private defaultConfig: MatDialogConfig,
        private dialog: MatDialog) {
    }

    /**
     * 確認ダイアログ
     *
     * example
     * ```
     * this.dialog.confirm('タイトル', '内容').subscribe(rtn => {
     *   if (rtn) {
     *     console.log('ok')
     *   } else {
     *     console.log('cancel or undefined(Esc key or Click outside the dialog)')
     *   }
     * });
     * ```
     *
     * @param {string} title ダイアログのタイトル
     * @param {string} content ダイアログの内容
     * @returns {Observable<boolean>} true:OK, false:cancel, undefined:ESC,Click outside the dialog
     * @memberof Dialog
     */
    public confirm(title: string, content: string): Observable<boolean> {
        const config = Object.assign({}, this.defaultConfig);
        config.data = {
            title: title,
            content: content,
            buttonPosition: 'right',
            iconName: 'help_outline',
            dialogPattern: 'confirm'
        }
        const ref = this.dialog.open(DialogComponent, config);
        return ref.afterClosed();
    }

    /**
     * エラーダイアログ
     *
     * example
     * ```
     * this.dialog.error('タイトル', '内容').subscribe(rtn => {
     *   if (rtn) {
     *     console.log('ok')
     *   } else {
     *     console.log('cancel or undefined(Esc key or Click outside the dialog)')
     *   }
     * });
     * ```
     * @param {string} title ダイアログのタイトル
     * @param {string} content ダイアログの内容
     * @returns {Observable<boolean>} true:close, undefined:ESC,Click outside the dialog
     * @memberof Dialog
     */
    public error(title: string, content: string): Observable<boolean> {
        const config = Object.assign({}, this.defaultConfig);
        config.data = {
            title: title,
            content: content,
            buttonPosition: 'right',
            iconName: 'highlight_off',
            dialogPattern: 'error',

        }
        // console.log(config)
        const ref = this.dialog.open(DialogComponent, config);
        return ref.afterClosed();
    }

    /**
     * 情報ダイアログ
     *
     * example
     * ```
     * this.dialog.info('タイトル', '内容').subscribe(rtn => {
     *   if (rtn) {
     *     console.log('ok')
     *   } else {
     *     console.log('cancel or undefined(Esc key or Click outside the dialog)')
     *   }
     * });
     * ```
     * @param {string} title ダイアログのタイトル
     * @param {string} content ダイアログの内容
     * @returns {Observable<boolean>} true:close, undefined:ESC,Click outside the dialog
     * @memberof Dialog
     */
    public info(title: string, content: string): Observable<boolean> {
        const config = Object.assign({}, this.defaultConfig);
        config.data = {
            title: title,
            content: content,
            buttonPosition: 'right',
            iconName: 'info_outline',
            dialogPattern: 'info'
        }
        const ref = this.dialog.open(DialogComponent, config);
        return ref.afterClosed();
    }

    /**
     * 警告ダイアログ
     *
     * example
     * ```
     * this.dialog.warning('タイトル', '内容').subscribe(rtn => {
     *   if (rtn) {
     *     console.log('ok')
     *   } else {
     *     console.log('cancel or undefined(Esc key or Click outside the dialog)')
     *   }
     * });
     * ```
     * @param {string} title ダイアログのタイトル
     * @param {string} content ダイアログの内容
     * @returns {Observable<boolean>} true:close, undefined:ESC,Click outside the dialog
     * @memberof Dialog
     */
    public warning(title: string, content: string): Observable<boolean> {
        const config = Object.assign({}, this.defaultConfig);
        config.data = {
            title: title,
            content: content,
            iconName: 'warning',
            dialogPattern: 'warning'
        }
        const ref = this.dialog.open(DialogComponent, config);
        return ref.afterClosed();
    }


    public loadingShow(): void {
        this.overlayEvent$.next(true)
    }

    public loadingHide(): void {
        this.overlayEvent$.next(false)
    }


    public subclassSelection(param: SubclassSelectionModel): Observable<string[]> {

        const config = <MatDialogConfig<SubclassSelectionModel>>Object.assign({}, this.defaultConfig);
        config.data = param

        var dialog = this.dialog.open(SubclassSelectionComponent, config)
        return dialog.afterClosed()
    }


    public formatSelection(): Observable<FormatSelectionResult> {
        const config = <MatDialogConfig<void>>Object.assign({}, this.defaultConfig);

        return this.dialog.open<
            FormatSelectionComponent,
            void,
            FormatSelectionResult>(FormatSelectionComponent, config).afterClosed()
    }

    public templateGroupDataInput(): Observable<TemplateGroupData> {
        const config = <MatDialogConfig<void>>Object.assign({}, this.defaultConfig);

        return this.dialog.open<
            TemplateGroupDataInputComponent,
            void,
            TemplateGroupData>(TemplateGroupDataInputComponent, config).afterClosed()
    }

    public taggingGroupSelection(param: TaggingGroupSelectionModel): Observable<TaggingGroupSelectionData> {

        const config = <MatDialogConfig<TaggingGroupSelectionModel>>Object.assign({}, this.defaultConfig);
        config.data = param

        var dialog = this.dialog.open(TaggingGroupSelectionComponent, config)
        return dialog.afterClosed()
    }

}
