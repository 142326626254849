import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Dictionary } from 'lodash';
import { UAC } from 'src/app/service/user/uac';
import { EndpointConfig } from 'src/app/config/endpoint-config';
import { DialogService } from 'src/app/service/dialog-service';
import { ProjectApiService } from 'src/app/service/server/project-api-service';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    private uac: UAC,
    private endpointConfig: EndpointConfig,
    private dialog: DialogService,
    private projectApiService: ProjectApiService,
  ) { }

  private datasetViewerLinks: { name: string, url: string, queryParams: { [key: string]: string } }[] = []

  ngOnInit() {
    this.dialog.loadingShow()
    this.projectApiService.getProjects()
      .pipe(finalize(() => this.dialog.loadingHide()))
      .subscribe((data) => {
        this.datasetViewerLinks = data.projects.map((project) => {
          return {
            name: project.projectName,
            url: `/tag/project/${project.projectName}/dataset-viewer`,
            queryParams: {}
          }
        })
      })
  }
  
  navigateToDatasetViewer(link: { url: string, queryParam: { [key: string]: string }, title: string }) {
    this.router.navigate([link.url], {
      queryParams: link.queryParam
    })
  }

  DownloadManual() {
    let url = this.endpointConfig.riaApi.tags.manual.getUrl()
    window.open(url, '_blank')
  }
}
