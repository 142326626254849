import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';

@Component({
    selector: 'snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.css'],
})
export class SnackBarComponent {


    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public messages: any,
        public snackBarRef: MatSnackBarRef<SnackBarComponent>
    ) { }

    onClose() {
        this.snackBarRef.dismiss();
    }
}
