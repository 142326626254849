import { Component, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/app/interface/user';
import { UAC } from 'src/app/service/user/uac';


@Component({
    'selector': 'app-header',
    'templateUrl': './header.component.html',
    'styleUrls': ['./header.component.css']
})
export class HeaderComponent {

    @Input() systemName: string;

    @Input() loginUser: User

    @Output() onLogout = new EventEmitter<void>()

    private isMenuOpened: boolean = false;
    private userInfo: { userName: string };
    private headerCss: string;

    constructor(
        private uac: UAC
    ) {
    }
}
