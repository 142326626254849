import { Injectable } from "@angular/core";
import { DialogService } from 'src/app/service/dialog-service';
import { Observable, MonoTypeOperatorFunction, OperatorFunction, of } from "rxjs";
import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpResponse } from '@angular/common/http';
import { tap } from "rxjs/operators";
import { NGXLogger } from "ngx-logger";


@Injectable()
export class HttpHelper {

    constructor(
        private logger: NGXLogger,
        private dialogService: DialogService
    ) {

    }

    getDefaultErrorHandler(): (res: HttpErrorResponse) => Observable<boolean> {
        return (res) => {
            var title: string;
            const messages: string[] = [];
            if (res instanceof Error) {
                this.logger.error(res);
            } else {
                switch (res.status) {
                    case 0:
                        title = 'server connection failed';
                        messages.push(res.statusText);
                        break;

                    case 404:
                        messages.push(res.url)

                    default:
                        title = res.error.message;
                        res.error.errors.forEach(element => {
                            let _path = ""
                            if (element['path']) {
                              _path = element.path + ': '
                            }
                            messages.push(_path + element.message)
                        });
                }
            }
            this.dialogService.loadingHide()
            return this.dialogService.error(title, messages.join('<br>'))
        }

    }
}

@Injectable()
export class CachingInterceptor implements HttpInterceptor {

    private _cache = {}

    constructor(
        private logger: NGXLogger
    ) { }

    isCachable(req: HttpRequest<any>) {
        return req.method.toLowerCase() == 'get' && req.headers.get('x-client-cachable')
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // continue if not cachable.
        if (!this.isCachable(req)) {
            this.logger.debug('not caching url: ', req.url)
            return next.handle(req);
        }

        const cachedResponse = this._cache[req.url];
        if (cachedResponse) {
            this.logger.debug('return cached url: ', req.url)
            return of(cachedResponse)
        } else {
            // No headers allowed in npm search request
            return next.handle(req.clone())
                .pipe(
                    tap(event => {
                        // There may be other events besides the response.
                        if (event instanceof HttpResponse) {
                            this.logger.debug('cache url: ', req.url)
                            this._cache[req.url] = event
                        }
                    })
                );
        }
    }
}
