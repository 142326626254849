import { Pipe, PipeTransform } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Pipe({
  name: 'keyValueFilter',
  pure: false
})
export class KeyValueFilterPipe implements PipeTransform {

  constructor(
    private logger: NGXLogger
  ) {}

  transform(value: any[], condition?: any): any {
    if (!value) return [];

    var keys = Object.keys(condition)

    return value.filter((x) => {
      var _ok = true
      for (var i = 0; i < keys.length; i++) {
        let propKey = keys[i]
        let propVal = condition[propKey]

        let target = x[propKey]

        if (typeof propVal == 'undefined' || typeof target == 'undefined' || target == null) {
          continue
        }

        if (typeof target === "string") {
          if (target.length == 0) continue;

          _ok = target.startsWith(propVal)
        } else {
          _ok = target == propVal
        }

        if (!_ok) break;
      }
      return _ok
    });
  }

}
