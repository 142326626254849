import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit {
    private errorMsg: string
    private returnUrl: string

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => (this.returnUrl=params['returnUrl']||"tag/home"))
    this.route.queryParams.subscribe((params) => (this.errorMsg=params['errorMsg']||'Unkown Error'))
  }
  
  returnTo() {
    console.log(this.returnUrl)
    this.router.navigate([this.returnUrl], {})
  }
}
