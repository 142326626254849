import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid';
import { TaggingColumn, SelectNode, Project, TagDefinition } from 'src/app/model/project/project'
import { AgGridSelectEditor, AgGridAttachComponent, AgGridBtnComponentParam, AgGridColorSelectorComponent, AgGridRemoveComponent, AgGridBtnComponent, AgGridFlagBtn } from 'src/app/utils/attribute-table';
import { CommonUtils } from 'src/app/utils/common';
import { WebTaggingComponent } from 'src/app/page/web-tagging/web-tagging.component';
import { TaggingHelper } from 'src/app/page/web-tagging/helpers/tagging-helper';

export interface TaggingColumnWithDefinition extends TaggingColumn {
    tagDefinition: TagDefinition
}


@Injectable()
export class ProjectConvertService {

    constructor() { }

    public toTaggingColumnWithDefinition(project: Project): TaggingColumnWithDefinition[] {

        let toTagdefinitionMap = {}
        project.tagDefinition.forEach(x => {
            toTagdefinitionMap[x.name] = x
        })

        return project.taggingColumn.map(x => {
            let _x = <TaggingColumnWithDefinition>x
            _x.tagDefinition = toTagdefinitionMap[x.definition]
            return _x
        })

    }

    public toColumnDefs(component: WebTaggingComponent) {
        // columnDefs初期化
        var itemColumnDefs = []
        var imageColumnDefs = []

        // type別のcolumn定義
        let bbox: () => ColDef = () => {
            return {
                headerName: '',
                field: 'bbox',
                width: 50,
                pinned: 'left',
                cellRendererFramework: AgGridAttachComponent,
                cellRendererParams: <AgGridBtnComponentParam>{
                    AgGridBtnComponent: {
                        isDisabled: (params: AgGridBtnComponentParam) => {
                            if (component.colorColumnParam) {
                                return true
                            }
                            return component.attachRowIndex >= 0 && component.attachRowIndex != params.rowIndex
                        },
                        isHidden: (params: AgGridBtnComponentParam) => params.value && params.value.length > 0,
                        onClick: component.attachBBox.bind(component)
                    }
                }
            }
        }

        let select: () => ColDef = () => {
            return {
                width: 120,
                cellEditorFramework: AgGridSelectEditor,
                cellEditorParams: {
                    AgGridSelectEditorParam: {
                        values: []
                    }
                },
                onCellValueChanged: component.onCellValueChanged.bind(component),
                editable: params => !TaggingHelper.checkDisabledProperties(params, component.project),
                cellStyle: params => TaggingHelper.createCellStyleFunction(TaggingHelper.checkDisabledProperties(params, component.project)),
                suppressNavigable: params => TaggingHelper.checkDisabledProperties(params, component.project),
            }
        }

        let colorPicker: () => ColDef = () => {
            return {
                width: 130,
                editable: false,
                cellRendererFramework: AgGridColorSelectorComponent,
                cellRendererParams: {
                    AgGridBtnComponent: {
                        isDisabled: (params: AgGridBtnComponentParam) => component.uneditable4Color(params, component.attachRowIndex, component.colorColumnParam),
                        isHidden: (params: AgGridBtnComponentParam) => false,
                        onClick: component.selectColor.bind(component),
                        doReset: (params: AgGridBtnComponentParam) => {
                            var row = params.api.getDisplayedRowAtIndex(params.rowIndex)
                            row.setDataValue(params.column, null)
                        },
                        index: null,
                        isArray: null
                    }
                }
            }
        }

        let flag: () => ColDef = () => {
            return {
                headerName: '',
                field: '',
                width: 80,
                cellRendererFramework: AgGridFlagBtn,
                cellRendererParams: <AgGridBtnComponentParam>{
                    AgGridBtnComponent: {
                        isHidden: (params: AgGridBtnComponentParam) => false,
                        isDisabled: (params: AgGridBtnComponentParam) => {
                            return component.colorColumnParam || component.attachRowIndex >= 0 || TaggingHelper.checkDisabledProperties(params, component.project)
                        }
                    }
                },
                onCellValueChanged: component.onCellValueChanged.bind(component),
                cellStyle: params => TaggingHelper.createCellStyleFunction(TaggingHelper.checkDisabledProperties(params, component.project)),
            }
        }

        let text: () => ColDef = () => {
            return {
                width: 200,
                onCellValueChanged: component.onCellValueChanged.bind(component),
                editable: params => !TaggingHelper.checkDisabledProperties(params, component.project),
                cellStyle: params => TaggingHelper.createCellStyleFunction(TaggingHelper.checkDisabledProperties(params, component.project)),
                suppressNavigable: params => TaggingHelper.checkDisabledProperties(params, component.project),
            }
        }

        let textArea: () => ColDef = () => {
            return {
                width: 200,
                cellEditor: 'agLargeTextCellEditor',
                onCellValueChanged: component.onCellValueChanged.bind(component),
                editable: params => !TaggingHelper.checkDisabledProperties(params, component.project),
                cellStyle: params => TaggingHelper.createCellStyleFunction(TaggingHelper.checkDisabledProperties(params, component.project)),
                suppressNavigable: params => TaggingHelper.checkDisabledProperties(params, component.project),
            }
        }

        let remove: () => ColDef = () => {
            return {
                headerName: '',
                field: '',
                width: 50,
                pinned: 'right',
                cellRendererFramework: AgGridRemoveComponent,
                cellRendererParams: <AgGridBtnComponentParam>{
                    AgGridBtnComponent: {
                        isHidden: (params: AgGridBtnComponentParam) => false,
                        isDisabled: (params: AgGridBtnComponentParam) => {
                            return component.colorColumnParam || component.attachRowIndex >= 0
                        },
                        onClick: component.remove.bind(component)
                    }
                }
            }
        }

        var taggingColumns = this.toTaggingColumnWithDefinition(component.project)

        itemColumnDefs.push(bbox())

        taggingColumns.sort(function (a, b) {
            return a.sort - b.sort;
        });

        for (let taggingColumn of taggingColumns) {

            let count = taggingColumn.count ? taggingColumn.count : 1
            let column: ColDef

            for (var i = 0; i < count; i++) {
                if (taggingColumn.tagDefinition.type == 'select') {
                    column = this.setCommonProperty(select, taggingColumn, i)
                    let optionValues = this.convertFromSelectNode(taggingColumn.tagDefinition.selectNode)
                    column.cellEditorParams = params => TaggingHelper.excludeHiddenTags(params, component.project, optionValues.map(CommonUtils.toNamedValue))

                } else if (taggingColumn.tagDefinition.type == 'colorPicker') {
                    column = this.setCommonProperty(colorPicker, taggingColumn, i)
                    column.cellRendererParams.AgGridBtnComponent.index = i
                    column.cellRendererParams.AgGridBtnComponent.isArray = taggingColumn.isArray

                } else if (taggingColumn.tagDefinition.type == 'flag') {
                    column = this.setCommonProperty(flag, taggingColumn, i)

                } else if (taggingColumn.tagDefinition.type == 'text') {
                    column = this.setCommonProperty(text, taggingColumn, i)

                } else if (taggingColumn.tagDefinition.type == 'textArea') {
                    column = this.setCommonProperty(textArea, taggingColumn, i)
                }

                if (taggingColumn.sort == 1) {
                    column.pinned = 'left'
                }

                if (taggingColumn.taggingLevel == 'item') {
                    itemColumnDefs.push(column)
                } else {
                    imageColumnDefs.push(column)
                }
            }
        }
        itemColumnDefs.push(remove())

        var columnDefs = {}
        // imageColumnDefsが全て非表示になっているかどうかチェックする。
        columnDefs['imageTags'] = this.checkHiddenAllColumns(imageColumnDefs)
            ? []
            : imageColumnDefs
            
        columnDefs['tags'] = itemColumnDefs
        return columnDefs
    }

    setCommonProperty(_column: () => ColDef, taggingColumn: TaggingColumn, i: number) {
        let count = taggingColumn.count ? taggingColumn.count : 1

        let column = _column()

        let headerNameSuffix = taggingColumn.isArray ? String(i + 1) : ''
        let fieldNameSuffix = taggingColumn.isArray ? '.' + String(i) : ''

        column.headerName = taggingColumn.property + headerNameSuffix
        column.field = taggingColumn.property + fieldNameSuffix
        // headeにカーソルを合わせた際に表示するテキスト
        column.headerTooltip = taggingColumn.property + headerNameSuffix
        column.hide = !taggingColumn.isVisible

        return column
    }

    convertFromSelectNode(selectNodes: SelectNode[]) {

        function getNameFromNode(optionValues, selectNodes) {
            for (let node of selectNodes) {
                optionValues.push(node.name)
                if (node.children.length > 0) {
                    getNameFromNode(optionValues, node.children)
                }
            }
        }

        let optionValues = []
        getNameFromNode(optionValues, selectNodes)
        return optionValues
    }

    checkHiddenAllColumns(columnDefs: ColDef[]) {
        return columnDefs.every(column => column.hide)
    }
}
