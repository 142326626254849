import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/service/dialog-service';
import { RouterService } from 'src/app/service/router-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Tagging Tool Client';

  isOverlay = false;
  constructor(
    private routerService: RouterService,
    private dialogService: DialogService) {

  }

  ngOnInit() {
    this.dialogService.overlayEvent$.subscribe(x => {
      setTimeout(() => {
        this.isOverlay = x
      });
    })

  }
}
