import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { first, map, finalize } from "rxjs/operators";
import { EndpointConfig } from "src/app/config/endpoint-config";
import { TemplateGroupList } from "src/app/model/template/template";
import { TemplateStatus, TemplateStatusList } from 'src/app/model/template/templateStatus';
import { CommonUtils } from 'src/app/utils/common';
import { NGXLogger } from "ngx-logger";
import { Project, Projects } from "src/app/model/project/project";
import { tap } from 'rxjs/operators';

@Injectable()
export class ProjectApiService {

  constructor(
    private http: HttpClient,
    private endpointConfig: EndpointConfig,
    private logger: NGXLogger
  ) {

  }

  public getProjects() {
    var url = this.endpointConfig.riaApi.project.getUrl()
    return this.http.get<Projects>(url)
      .pipe(
        map((data) => {
          data.projects.forEach((project) => {
            project.cDate = CommonUtils.toMillFromS(project.cDate);
          })
          return data
        }),
        tap((data) => {
          // projectNameでsortする。
          data.projects.sort(function (a, b) {
            if (a.projectName < b.projectName) return -1;
            if (a.projectName > b.projectName) return 1;
            return 0
          })
        })
      )
  }

  public getProject(projectName: string, includeDatasetFundamental: boolean = false): Observable<Project> {
    let options = includeDatasetFundamental
      ? { params: new HttpParams().set('includeDatasetInfo', 'true') }
      : {}

    return this.http.get<Project>(this.endpointConfig.riaApi.project.projectName.getUrl(projectName), options)
      .pipe(
        tap(project => {
          project.taggingColumn.sort(function (a, b) {
            if (a.sort < b.sort) return -1;
            if (a.sort > b.sort) return 1;
            return 0;
          })
        })
      )
  }
}
