import { HttpClient, HttpParams } from '@angular/common/http';
import { OnInit, Component } from '@angular/core';
import { CommonUtils, SearchPageUtils } from 'src/app/utils/common';
import { HttpHelper } from 'src/app/utils/http-helper';
import { EndpointConfig } from 'src/app/config/endpoint-config';
import { Project } from 'src/app/model/project/project';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'src/app/service/dialog-service';
import { finalize } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-project-export',
  templateUrl: './project-export.component.html'
})
export class ProjectExportComponent implements OnInit {

  project: Project = null;
  projectName: string = ''

  metaList: string[] = []
  categoryList: string[] = []
  subclassList: string[] = []

  private searchFormDefinition = {
    meta: {
      type: Array,
      predicate: (x) => !CommonUtils.isEmpty(x)
    },
    category: {
      type: Array,
      predicate: (x) => !CommonUtils.isEmpty(x)
    },
    subclass: {
      type: Array,
      predicate: (x) => !CommonUtils.isEmpty(x)
    },
    includeHeader: {
      type: String
    },
    minRow: {
      type: String,
      predicate: (x) => !CommonUtils.isEmpty(x)
    },
    includeUqColor: {
      type: String
    },
    uqColorOverrideDefinition: {
      type: Array,
      predicate: (x) => !CommonUtils.isEmpty(x)
    }
  }

  searchForm = {
    meta: [],
    category: [],
    subclass: [],
    includeHeader: true,
    minRow: '0',
    includeUqColor: false,
    uqColorOverrideDefinition: []
  }

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private route: ActivatedRoute,
    private httpHelper: HttpHelper,
    private dialog: DialogService,
    private endpointConfig: EndpointConfig
  ) {
  }

  ngOnInit() {
    this.dialog.loadingShow()
    this.projectName = this.route.snapshot.paramMap.get('projectName')
    this.getProject(this.projectName)
      .pipe(finalize(() => this.dialog.loadingHide()))
      .subscribe((project) => {
        this.project = project
        // 変更後と比較するため、初期状態を保持する。

        // meta検索用のプルダウン作成
        this.project.datasetInfo.meta.forEach((_meta) => {
          _meta.value.forEach((val) => {
            this.metaList.sort().push(_meta.key + ':' + val)
          })
        })

        // 検索フォームで使用するcategoryのリスト作成
        let _subclassList = new Set()
        this.project.datasetInfo.category.forEach((val) => {
          this.categoryList.push(val.category)
        })
      }, this.httpHelper.getDefaultErrorHandler)

  }

  getProject(projectName: string) {
    let options = { params: new HttpParams().set('includeDatasetInfo', 'true') };
    let url = this.endpointConfig.riaApi.project.projectName.getUrl(projectName)
    return this.http.get<Project>(url, options)
  }

  // ダイアログ画面への処理
  displaySubclassSelection(selectedCategories) {

    // 選択されたcategoryに紐づくsubclassのリストを作成する。
    var _subclassList: string[] = []
    this.project.datasetInfo.category
      .filter(val => selectedCategories.includes(val.category))
      .forEach(val => {
        Array.prototype.push.apply(_subclassList, val.subclass);
      })

    this.subclassList = Array.from(new Set(_subclassList)).sort()

    // ダイアログを表示する。
    this.dialog.subclassSelection({
      selectedSubclass: this.subclassList.map((subclass) => {
        return {
          name: subclass,
          isSelected: this.searchForm.subclass.indexOf(subclass) >= 0
        }
      }),
      taggingPreset: this.project.taggingPreset
    }).subscribe(
      (selectedSubclasses: string[]) => {
        if (selectedSubclasses == undefined) {
          selectedSubclasses = []
        }
        this.searchForm.subclass = selectedSubclasses
      })
  }

  download(format: string) {
    let conditions = SearchPageUtils.searchFormToConditions(this.searchFormDefinition, this.searchForm)
    let _params = new HttpParams()
    for (let key of Object.keys(conditions)) {
      _params = _params.set(key, conditions[key])
    }
    let url = this.endpointConfig.riaApi.project.projectName.qlikview.getUrl(this.projectName) + '?' + _params.toString()
    this.logger.debug('download url:', url)
    window.open(url, '_blank')
  }
}
