import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
  } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { interval, Subscription } from 'rxjs';
import { throttle, timeInterval } from 'rxjs/operators';
import { CursorPosition } from '../../utils/common';

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.css']
})
export class ZoomComponent implements OnInit, OnDestroy, OnChanges {
  @Input('imgSrc')
  imgSrc: string
  @Input('position')
  position$: EventEmitter<CursorPosition>
  @Input('mag')
  mag: number

  @ViewChild('largeImage')
  largeImage: ElementRef<HTMLDivElement>
  @ViewChild('mouseTracker')
  mouseTracker: ElementRef<HTMLDivElement>

  isShow = true
  img: HTMLImageElement
  ctx: CanvasRenderingContext2D

  unsubscribers: Subscription[] = []

  constructor(private logger: NGXLogger) {}

  ngOnInit() {
    this.isShow = true
    this.img = new Image()
    this.img.onload = () => {
      this.isShow = false
      this.largeImage.nativeElement.style.background = `url(${this.img.src}) no-repeat 0 0 scroll`
      this.largeImage.nativeElement.style.backgroundPosition = `${0.5 * 100}% ${0 * 100}%`

      this.unsubscribers = [
        this.position$.pipe(throttle(() => interval(10))).subscribe(p => {
          this.mouseTracker.nativeElement.style.left = `${p.x * 100}%`
          this.mouseTracker.nativeElement.style.top = `${p.y * 100}%`
          this.largeImage.nativeElement.style.backgroundPosition = `${p.x * 100}% ${p.y * 100}%`
        })
      ]
    }
    this.img.src = this.imgSrc
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.imgSrc && !changes.imgSrc.firstChange) {
      this.ngOnDestroy()
      this.ngOnInit()
    }
  }

  ngOnDestroy() {
    this.largeImage.nativeElement.style.background = ``
    this.unsubscribers.forEach(x => x.unsubscribe())
  }

  zoomIn() {
    if (!this.mag) {
      this.mag = (this.img.naturalWidth / this.largeImage.nativeElement.clientWidth) * 100
      this.logger.debug('init mag:', this.mag)
    }

    if (this.mag > 1000) {
      return
    }
    this.mag += 100
  }

  zoomOut() {
    if (!this.mag) {
      this.mag = (this.img.naturalWidth / this.largeImage.nativeElement.clientWidth) * 100
      this.logger.debug('init mag:', this.mag)
    }

    this.mag -= 100

    if (this.mag < 100) {
      this.mag = 100
    }
  }
}
