export const ROLEGROUPE = {
    admin:[
        "S-VOM-DEV_FR_ALL_VAT_ADMIN", 
        "S-VOM-STG_FR_ALL_VAT_ADMIN", 
        "S-VOM-PRD_FR_ALL_VAT_ADMIN",

    ],
    manager:[
        'S-VOM-DEV_FR_ALL_VAT_MGR',
        'S-VOM-STG_FR_ALL_VAT_MGR',
        'S-VOM-PRD_FR_ALL_VAT_MGR',
    ],
    annotator:[
        "S-VOM-DEV_FR_ALL_VAT_ANNOT", 
        "S-VOM-STG_FR_ALL_VAT_ANNOT", 
        "S-VOM-PRD_FR_ALL_VAT_ANNOT"
    ]
}