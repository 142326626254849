import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, Observable, of } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { filter, finalize, mergeMap, tap, map } from 'rxjs/operators';
import { EndpointConfig } from 'src/app/config/endpoint-config';
import { Project, ProjectEdit } from 'src/app/model/project/project';
import { TemplateStatusList } from 'src/app/model/template/templateStatus'
import { DialogService } from 'src/app/service/dialog-service';
import { ProjectApiService } from 'src/app/service/server/project-api-service';
import { CommonUtils } from 'src/app/utils/common';
import { ProjectConvertService } from '../../service/project/project-convert-service';
import { HttpHelper } from '../../utils/http-helper';
import { ProjectEditOperationMixin, ProjectEditTagDefinition, ProjectEditTaggingColumn } from '../project-regist/project-regist.component';

@Component({
  selector: 'app-project-detail',
  templateUrl: './../project-regist/project-regist.component.html',
  styleUrls: ['./../project-regist/project-regist.component.css'],
})
export class ProjectDetailComponent extends ProjectEditOperationMixin implements OnInit {

  static UPDATE_CONFIRM = (project: Project) => {
    return `Project: ${project.projectName} <br>
            Tagging Group: ${project.taggingGroup} <br>
            上記のProjectを更新します。よろしいですか？`
  }

  static LOCK_LEVEL_INFO = (lockLevel: string) => {
    return `Lock Levelを下記の通りに更新しました。<br><br>
            Lock Level: ${lockLevel}<br><br>
            画面を更新します。<br>`
  }

  static BQ_UPDATE_CONFIRM = 'BQの更新を開始します(更新に15分ほどかかります)。よろしいですか？';
  static BQ_UPDATE_FAILURE = '更新に失敗しました。システム管理者に連絡してください。';
  static BQ_UPDATE_SUCCESS = '更新を開始しました。';

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private endpointConfig: EndpointConfig,
    private dialog: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private httpHelper: HttpHelper,
    private projectApiService: ProjectApiService,
    private projectConvertService: ProjectConvertService,
  ) {
    super()
    this.pageType = 'Detail'
    this.isEditMode = false
  }


  public projectName: string = ''
  public description: string = ''
  public taggingGroup: string = ''
  public prevProject: Project
  public prevSelectNodeMap = {}
  public project: Project

  public projectEdit: ProjectEdit = {
    description: [],
    taggingPreset: [],
    tagDefinition: [],
    taggingColumn: []
  }

  ngOnInit() {
    this.dialog.loadingShow()

    this.projectName = this.route.snapshot.paramMap.get('projectName')
    this.projectApiService.getProject(this.projectName).pipe(finalize(() => this.dialog.loadingHide()))
      .subscribe((project) => {
        // プロジェクト情報セット
        this.project = project
        this.description = project.description
        this.taggingGroup = project.taggingGroup
        this.tagDefinitions = <ProjectEditTagDefinition[]>project.tagDefinition
        this.taggingColumns = <ProjectEditTaggingColumn[]>project.taggingColumn

        // 変更後と比較するため、初期状態を保持する。
        this.prevProject = <Project>_.cloneDeep(this.project)
        this.prevProject.tagDefinition.forEach(prevTagDefinition => {
          if (prevTagDefinition.type == 'select') {
            this.prevSelectNodeMap[prevTagDefinition.name] = this.projectConvertService.convertFromSelectNode(prevTagDefinition.selectNode)
          }
        })

        // taggingPresetは[ val,... ]の形式からng-selectの[{ label: 'val' },...]の形式で使用するため、変換する。
        this.taggingPresetForm = this.project.taggingPreset.map(x => {
          return { label: x, value: x }
        })
      })
  }

  // ------------------------
  // Edit Mode Config section
  // ------------------------
  // requiredのボタン非活性制御
  public disabledRequiredBtn(index: number, target: ProjectEditTaggingColumn) {
    if (this.isEditMode) {

      // 追加されたColumnの場合は、活性状態、
      return target.isNew
        ? false
        : !this.prevProject.taggingColumn[index].required
    }

    return true
  }

  public getMinCount(index: number) {
    if (this.prevProject.taggingColumn.length > index) {
      return this.prevProject.taggingColumn[index].count
    }

    return super.getMinCount(index)
  }

  // ------------------------
  // Project Update section
  // ------------------------
  doUpdate() {
    this.dialog.confirm('Update', ProjectDetailComponent.UPDATE_CONFIRM(this.project))
      .pipe(
        filter(x => x),
        tap(() => this.dialog.loadingShow()),
        mergeMap(x => {
          for (let tagDefinition of this.tagDefinitions) {
            if (tagDefinition.selectNode) {
              tagDefinition.selectNode = this.deletePropertyFromTreeNode(tagDefinition.selectNode)
            }
          }

          this.projectEdit.description = [this.prevProject.description, this.description]
          //  ng-selectで[{ label: 'val' },...]の形式になるため、[ val,... ]の形式に変更する。
          this.projectEdit.taggingPreset = [this.prevProject.taggingPreset, this.taggingPresetForm.map(x => x.label)]
          // tagDefinitionをprojectEditの形式に変換
          this.toProjectEditFromTagDefinition()
          // taggingColumnをprojectEditの形式に変換
          this.toProjectEditFromTaggingColumn()

          this.logger.debug('Project Update ', this.projectEdit)
          return this.http.put(this.endpointConfig.riaApi.project.projectName.getUrl(this.projectName), this.projectEdit)
        }),
        finalize(() => this.dialog.loadingHide())
      )
      .subscribe(
        () => {
          this.dialog.info('Info', '更新が正常に完了しました。').subscribe(x => {
            this.isEditMode = false
            this.projectEdit = {
              description: [],
              taggingPreset: [],
              tagDefinition: [],
              taggingColumn: []
            }
            this.ngOnInit()
          })
        },
        (res: HttpErrorResponse) => {
          if (res.status == 400) {
            var messages = []
            res.error.errors.forEach(e => {
              messages.push(e.path + ': ' + e.message)
            })
            this.dialog.error('Error', messages.join('<br>'))
            // 初期状態に戻す
            this.projectEdit = {
              description: [],
              taggingPreset: [],
              tagDefinition: [],
              taggingColumn: []
            }
          } else {
            this.httpHelper.getDefaultErrorHandler()(res)
          }
        }
      )
  }

  toProjectEditFromTagDefinition() {
    if (this.project.tagDefinition.length > this.prevProject.tagDefinition.length) {
      let addTagDefinitions = this.tagDefinitions.slice(this.prevProject.tagDefinition.length, this.tagDefinitions.length).map(this.toTagDefinition)
      let _addTagDefinitions = addTagDefinitions.map((val) => {
        return {
          command: 'add',
          data: val
        }
      })
      // command: addを追加
      this.projectEdit.tagDefinition = _addTagDefinitions
    }

    let updateTagDefinitions = this.tagDefinitions.slice(0, this.prevProject.tagDefinition.length).map(this.toTagDefinition)
    for (let i in updateTagDefinitions) {
      // command: updateを追加
      this.projectEdit.tagDefinition.push(
        {
          command: 'update',
          data: [this.prevProject.tagDefinition[i], updateTagDefinitions[i]]
        }
      )
    }
  }

  toProjectEditFromTaggingColumn() {
    if (this.project.taggingColumn.length > this.prevProject.taggingColumn.length) {
      this.projectEdit.taggingColumn = this.taggingColumns.slice(this.prevProject.taggingColumn.length).map(this.toTaggingColumn)
        .map((val) => {
          return {
            command: 'add',
            data: val
          }
        })
    }
    let updateTaggingColumns = this.taggingColumns.slice(0, this.prevProject.taggingColumn.length).map(this.toTaggingColumn)
    for (let i in updateTaggingColumns) {
      // command: updateを追加
      this.projectEdit.taggingColumn.push(
        {
          command: 'update',
          data: [this.prevProject.taggingColumn[i], updateTaggingColumns[i]]
        }
      )
    }
  }

  navigateToStatusList() {
    var fromDate = new Date()
    var toDate = new Date();
    if (this.project.taggingGroup == 'none') {
      fromDate.setDate(fromDate.getDate() - 7)
    } else {
      fromDate.setMonth(fromDate.getMonth() - 3)
    }

    var fromDateYmd = CommonUtils.toStringFromDate(fromDate);
    var toDateYmd = CommonUtils.toStringFromDate(toDate);
    this.router.navigate([`tag/project/${this.projectName}/status-list`], {
      queryParams: {
        fromDate: fromDateYmd,
        toDate: toDateYmd
      }
    })
  }

  triggerJenkins() {
    const url = this.endpointConfig.riaApi.project.jenkins.getUrl(this.projectName);

    this.dialog.confirm('Update', ProjectDetailComponent.BQ_UPDATE_CONFIRM).subscribe(result => {
      if (result === true) {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = event => {
          if (xhr.status >= 200 && xhr.status < 300) {
            this.dialog.info('Info', ProjectDetailComponent.BQ_UPDATE_SUCCESS);
          } else {
            this.dialog.error('Error', ProjectDetailComponent.BQ_UPDATE_FAILURE + '<br>' +
              'Error: ' + xhr.status + '&nbsp;' + xhr.statusText);
          }
        };
        xhr.onerror = event => {
          this.dialog.error('Error', ProjectDetailComponent.BQ_UPDATE_FAILURE + '<br>' +
            'Error: Failed to make request.');
        };

        xhr.send(JSON.stringify('{}'));
        return;
      }
    });

  }

  displayLockLevelSelection(projectName: string) {
    let getUrl = this.endpointConfig.riaApi.project.projectName.templates.getUrl(this.projectName)
    let getQueryParams = new HttpParams({ fromObject: { includeAllLockLevel: "True", status: "imported" } })
    let lockLevelSet = new Set<string>()
    let lockLevelArray = []

    let putUrl = this.endpointConfig.riaApi.project.projectName.taggingGroup.putUrl(projectName)
    let putBody = {}

    this.dialog.loadingShow()
    this.http.get<TemplateStatusList>(getUrl, { params: getQueryParams })
      .pipe(
        // ロックレベル情報セット
        tap((lockLevel) => {
          lockLevel.statuses.forEach(templateStatus => {
            lockLevelSet.add(templateStatus.lock.lockLevel)
          })
          lockLevelSet.add('none')
          lockLevelSet.add(this.taggingGroup)
          lockLevelArray = Array.from(lockLevelSet).map((lockLevel) => {
            return {
              name: lockLevel,
              isCurrent: lockLevel === this.taggingGroup
            }
          })
        }),
        tap(() => this.dialog.loadingHide()),
        // モーダルオープン
        mergeMap(() => {
          return this.dialog.taggingGroupSelection({
            taggingGroupSelection: lockLevelArray
          })
        }),
        // モーダルでの操作受け取り
        filter((x) => x.ok),
        tap(() => this.dialog.loadingShow()),
        mergeMap((taggingGroupNext) => {
          putBody['taggingGroupNext'] = taggingGroupNext.selectedTaggingGroupName
          putBody['taggingGroupPrev'] = this.taggingGroup
          return this.http.put<Project>(putUrl, putBody)
        }),
        finalize(() => this.dialog.loadingHide())
      ).subscribe(
        (data) => {
          // put正常終了時の処理
          this.dialog.info('Info', ProjectDetailComponent.LOCK_LEVEL_INFO(data.taggingGroup)).subscribe(
            () => location.reload()
          )
        },
        (res: HttpErrorResponse) => {
          // put異常終了時の処理
          if (res.status == 400 || res.status == 404 || res.status == 409) {
            let messages = <string[]>res.error.errors.map(e => e.message)
            this.dialog.error('Error', messages.join('<br>'))
          } else {
            this.httpHelper.getDefaultErrorHandler()(res)
          }
        }
      )
  }
}
